
import React , { useState , Fragment, useEffect } from 'react';
import { db , storage, auth } from '../Firebase';
import { query, collection, getDocs, where, addDoc, updateDoc, doc} from "firebase/firestore";

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import { ref , uploadBytes } from "firebase/storage";



// import "../assets/css/Login.css";
// import "../assets/css/headerStyle.css";
function AddCon() {
  
    const [newName , setNewName] = useState("");
    const [newAdress, setOption] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newTag, setNewTags] = useState("");
    const [newCategorie, setCathegorie] = useState("");
    const [newduree, setduree] = useState("");
    const [imageUpload, setImageUpload] = useState(null);

    const [user, loading, error] = useAuthState(auth);
    const [id, setId] = useState("");
    const navigate = useNavigate();

    
  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "Organisme"), where("uidd", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setId(data.uidd);
      console.log(data.uidd)
      
    } catch (error) {
      console.error(error);
    }
  };

  const [Game, setGame] = useState([]);
  const [total, settotal]=useState({});

  function merge(...arr){
    return arr.reduce((acc, val) => {    
      return { ...acc, ...val  };
    }, {});
  }
  
  async function getGame(){
    var flat = [];
    const cat=["Commercialisation","Financement","Production", "Prototypage", "etudedemarche"]
    for (var i=0 ; i<5 ; i++){
    const gameCollectionref = collection(db, `Organisme/${user.uid}/${cat[i]}`)
    await getDocs(gameCollectionref).then(response=>{ var g = response.docs.map(doc => ({data: doc.data(), id: doc.id,}))
    setGame(g[i]);
    console.log(g);
    flat.concat(g);
    console.log(flat)
    // console.log(total)
    // // var obj=Object.assign([], total, g);
    // // console.log(obj)
    
    
    // // settotal(total.concat(g))
    // // const array = array.concat(Game);
    // // settotal(array)
    // var obj3 = merge(total, g);
    // settotal(obj3);
    // console.log(obj3)
   
    
    })
    .catch(error => console.log(error.message));
    
    
  }
  

    settotal(flat)
    
  

 
}


  useEffect(() => {
    if (loading) return navigate("/AddContributor");;
    if (!user) return navigate("/");

    fetchUserName();
    // getGame();


 
  
  }, [user, loading]);

  
  function load (){
        
    setTimeout(function(){
        window.location.reload(true);
    console.log("ok loade");
    },5000);

}
 const [validation, setValider]= useState("");
 const [validationN, setValiderN]= useState("");
 
 const Add = async (e) => {
  var tab = newTag.split(" ");   
  

    for(var i = 0; i < tab.length; i++){
     console.log(" " + tab[i] + " ");
     tab[i]="#"+tab[i];
      
    }
  //  function(docRef) { const userDoc = doc(db, "Startup", docRef.id);
  //   updateDoc(userDoc, {
  //    uidd: docRef.id
  //     });
     
    if(imageUpload == null || newName == "" || newAdress== "" || newEmail == "" || newduree=="" || newTag=="" || newCategorie=="") return(setValiderN("Veuillez remplir les champs correctement"));
    const imageRef = ref(storage, `images/logoInter/${imageUpload.name}`);   
    const path = `https://firebasestorage.googleapis.com/v0/b/startinblock-fd5c0.appspot.com/o/images%2FlogoInter%2F${imageUpload.name}?alt=media`;
   uploadBytes(imageRef , imageUpload).then(() => {
     console.log(path);
 });  
     e.preventDefault()
     try {
      console.log(id)
       await addDoc(collection(db, `Organisme/${user.uid}/${newCategorie}`), {
         adresse: newAdress, email: newEmail, tags: tab, nom: newName, logo: path , categorie: newCategorie , etat: true ,duree: newduree
       }).then(function(docRef) 
       { const userDoc = doc(db, `Organisme/${user.uid}/${newCategorie}`, docRef.id);
       updateDoc(userDoc, {
        uidd: docRef.id
         })},
        setValider("L'intervenant a été creer"),load());
     
       
     } catch (err) {
      setValiderN("veuillez remplir les champs correctement");
       
     }
 
     
         
       
 }
 
   
    return (
<section>
        <form  id="AddOrganization" className='AddOrganization' style={{marginTop:"20%"}}>
                <div className="box-title-login">
                    <h3 className="titre_2">Ajouter intervenant</h3>
                    <p style={{color: "green", textAlign:"center"}}>{validation}</p>
                    <p style={{color: "red", textAlign:"center"}}>{validationN}</p>
                </div>
                <label >Logo de l'intervenant (.PNG 250*250)</label>
                <label className="uploadFile">
                <span className="filename"></span>
                <input onChange={(event) => {setImageUpload(event.target.files[0])}}  type="file" className="form-controll Addorg" name="file" accept="image/png, image/jpeg"/>
                 </label>
                <label >Nom de l'intervenant</label>
                <input onChange={(event) => {setNewName(event.target.value);}} id="name" className="Addorg" name="org_name" tabIndex="1" aria-required="true" required type="text"/>
                <label >Adress de l'intervenant</label>
                <input onChange={(event) => {setOption(event.target.value);}} id="name" className="Addorg" name="org_name" tabIndex="1" aria-required="true" required type="text"/>
                <label >Email de l'intervenant</label>
                <input onChange={(event) => {setNewEmail(event.target.value);}} id="email" className="Addorg" name="org_name" tabIndex="1" aria-required="true" required type="text"/>
                <label >Les tags (Tapez un espace entre les tags)</label>
                
                <input onChange={(event) => {setNewTags(event.target.value);}} id="tag" className="Addorg" name="org_name" tabIndex="1" aria-required="true" required type="text"/>
                <label >Durée en mois</label>
                
                <input onChange={(event) => {setduree(event.target.value);}} id="tag" className="Addorg" name="org_name" tabIndex="1" aria-required="true" required type="number"/>
                
                <label style={{marginRight:"10px"}}>Catégorie</label>
                <select multiple name='selection' className="Addorg" onChange={(event) => {setCathegorie(event.target.value);}}>
                    <option value="etudedemarche">Étude de marché</option>
                    <option value="Prototypage">Prototypage</option>
                    <option value="Financement">Financement</option>
                    <option value="Production">Production</option>
                    <option value="Commercialisation">Commercialisation</option>
                </select>
                <div style={{textAlign:'center'}}>
                <button onClick={ Add } className="add">Ajouter</button>
                </div>
        </form>
        </section>
        );
    }
    export default AddCon;