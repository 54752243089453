import React , { useState , Fragment, useEffect , useRef} from 'react';
import {Link} from 'react-router-dom';
import { Tabs,  TabPanel  } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { db, auth } from '../../src/Firebase';

import {collection, doc} from 'firebase/firestore';

import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";

//  import '../assets/css/style.css';
// import '../assets/css/shortcodes.css';
// import '../assets/css/bootstrap.css';



//<li key={Math.random()}>{doc.nom}</li>

                        
export default function ChildrenList({ path }) {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const [id, sendid] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const query = collection(db, path);
  const [docs, loadingy, errorr] = useCollectionData(query);
  console.log(docs)


  function modifier(id, categorie)

{
  console.log(id);
  navigate("/UpdateInter", {
    state: {id : id, cat: categorie},
  });
}


  return (
    <div >
    <ul style={{    marginTop: "5%"}}>
      
      {loading && "Loading..."}
      {/* //margin:"5% 0% 5% 20%" */}
      
        <table style={{width:"60%"}}>
  
        
      
      <tr> <th >Image</th> <th>Nom</th> <th>tags</th> <th>Adress</th> <th>Durée</th><th>Categorie</th></tr>
      {docs?.map((doc) => (
      <tr key={doc.id}><td style={{width:"4%"}}><img src={doc.logo} style={{width:"50%"}}/></td> <td style={{width:"4%"}}>{doc.nom}</td> <td style={{width:"4%"}}>{doc.tags}</td><td style={{width:"2%"}}>{doc.adresse}</td><td style={{width:"4%"}}>{doc.duree} mois</td> <td style={{width:"4%"}}>{doc.categorie}</td> <td style={{width:"2%"}}><button className="fl-button" onClick={()=>{modifier(doc.uidd, doc.categorie)}}     >Modifier</button></td></tr>
      ))}
     
      </table>
        
      
    </ul>
    </div>
    
  );
}  

