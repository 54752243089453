
import styled from 'styled-components';

import { Link } from 'react-router-dom';



import { auth, db, logout } from "../../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { query, collection, getDocs, where } from "firebase/firestore";
// import logo1 from "./sr";



const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  width: 140%;

  li {
    padding: 18px 10px;
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #e95717;
    
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;

    li {
      color: #FFFFFF;
    }
    a{
      color: #FFFFFF;
    }
  }
`;

const RightNav = ({ open }) => {

  
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "Organisme"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setName(data.uid);
      console.log(data.uid)
      
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    console.log(user.uid)
     fetchUserName();
  }, [user, loading]);

  
  return (
    <Ul open={open}>

      <li ><a href="/StartupList" >Liste des startups</a></li>
        <li ><a href="/AddContributor" >Ajouter des intervenants</a></li>
        <li ><a href="/ListeInter" >Liste des intervenants</a></li>
        <li ><a href="/UpdateOrg" >Modifier mes informations</a></li>
        
        <li><a href="" onClick={logout}>Se Deconnecter</a></li>
    </Ul>
  )
}

export default RightNav
