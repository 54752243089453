import React from 'react';
import { Modal } from "react-bootstrap";
import { auth, db, logout } from "../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { query, collection, getDocs, where ,onSnapshot , doc, updateDoc,serverTimestamp, orderBy, limit} from "firebase/firestore";
import ListeInter from '../pages/ListeInterv';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


import "../assets/css/geo.css"
import "../assets/css/csspour.scss"
import { set, update } from 'firebase/database';
import { async } from '@firebase/util';
import "../assets/css/style.css"


const Idiation = (props) => {
  

 




       

        
     

   
  useEffect(() => {  

  }, []);
  
    
return (


    <Modal
    show={props.show}
    onHide={props.onHide}
    
  >

    <Modal.Header closeButton></Modal.Header>
    <div className="circle-wrap">
        <div className="circle">
         
       <p>{props.id}</p>
          
          
        </div>
     
    </div> 
    </Modal>
    
  );
};

export default Idiation;
