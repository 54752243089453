
import { initializeApp } from "firebase/app";
import { getFirestore, query, getDocs, collection, where, addDoc, updateDoc ,doc, setDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { Link, useNavigate } from "react-router-dom";
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut} from "firebase/auth";

// import {collection, getDocs, orderBy , deleteDoc, setDoc} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDNoBSB9kW9tw2rZJswSfhAD4D1Sh--duU",
  authDomain: "startinblock-fd5c0.firebaseapp.com",
  databaseURL: "https://startinblock-fd5c0-default-rtdb.firebaseio.com",
  projectId: "startinblock-fd5c0",
  storageBucket: "startinblock-fd5c0.appspot.com",
  messagingSenderId: "902998496060",
  appId: "1:902998496060:web:a8bfd75aab35d51b2c0439"
};


const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);
let erreur = "";

// const signInWithGoogle = async () => {
//   try {
//     const res = await signInWithPopup(auth, googleProvider);
//     const user = res.user;
//     const q = query(collection(db, "users"), where("uid", "==", user.uid));
//     const docs = await getDocs(q);
//     if (docs.docs.length === 0) {
//       await addDoc(collection(db, "users"), {
//         uid: user.uid,
//         name: user.displayName,
//         authProvider: "google",
//         email: user.email,
//       });
//     }
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    console.log(user)
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err.message);
    // alert(err.message);
  }
};
// const [name, setName] = useState("");


const logInWithEmailAndPassword = async (email, password) => {


 if (email==="admin@gmail.com"){ 
      try {
              
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err.message);
      erreur= "Verifiez vos coordonnées";
       console.log(erreur)
       
       
    }
 }else {
// { alert("Votre compte n'est pas active")}    }
    try {
      const q = query(collection(db, "Organisme"), where("email", "==", email));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      console.log(data)
      const v =data.isVerified
      const admin=data.email
      console.log(admin)
   
    
      if (v === true)
  
      {
        try {
          
          await signInWithEmailAndPassword(auth, email, password);
        } catch (err) {
          console.error(err.message);
          alert("Verifiez vos coordonnées");
          erreur= "Verifiez vos coordonnées";
          
          
        }
      }else { alert("Votre compte n'est pas active"); erreur= "Votre compte n'est pas active";}       
                
       
       } catch (err) {
                console.error(err)
                erreur= "Verifiez vos coordonnées";;
              }
                   
      
            }
  
};
// function(docRef) { const userDoc = doc(db, "Startup", docRef.id);
//     updateDoc(userDoc, {
//       uidd: docRef.id
//       });

async function registerWithEmailAndPassword(name, email, password, gender, age, adress, logo, ville) {
  
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    console.log(user.uid)
    await setDoc(doc(db, "Startup", user.uid ), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      gender,
      age,
      adress,
      isVerified: false,
      activation: true,
      logo,
      ville,
    }).then(function(docRef) {  logout(auth)})

  } catch (err) {
    console.error(err);
    alert(err.message);
    const er = err.message;
  }
}

async function registerWithEmailAndPasswordTest(name, email, password, gender, age, adress, logo, ville, OrganismeId) {
  
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    console.log(user.uid)
    await setDoc(doc(db, "Startup", user.uid ), {
      uid: user.uid,
      name,
      email,
      gender,
      age,
      adress,
      isVerified: false,
      activation: true,
      logo,
      ville,
      OrganismeId,
    }).then(function(docRef) {  logout(auth)})

  } catch (err) {
    console.error(err);
    alert(err.message);
    const er = err.message;
  }
}
async function registerWithEmailAndPasswordOrg(name, email, password, adress, logo, ville) {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await setDoc(doc(db, "Organisme", user.uid), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      adress,
      isVerified: false,
      activation: true,
      logo,
      ville,
    }).then(function(docRef) {  logout(auth)})
      
      
     
    
  } catch (err) {
    console.error(err);
    alert(err.message);
    const er = err.message;
  }
}
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
    const er = err.message;
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  storage,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  registerWithEmailAndPasswordOrg,
  registerWithEmailAndPasswordTest,
  sendPasswordReset,
  logout,
  erreur,
  
};