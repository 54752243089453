import React , { useState , Fragment, useEffect , useRef} from 'react';
import {Link} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CardModal from './CardModal';
import {storage , db } from '../../src/Firebase';
import { getDatabase, ref, query, orderByChild } from "firebase/database";
import {collection, getDocs, orderBy ,updateDoc ,doc, deleteDoc, setDoc, loadBundle, where} from 'firebase/firestore';
import emailjs from '@emailjs/browser';



const ListOrg = props => {
//     const [Game, setGame] = useState([]);
    

//     useEffect(()=> {
//     getGame()
//     }, [])

//       function getGame(){
//         const gameCollectionref = collection(db, 'Organisme')
//         getDocs(gameCollectionref).then(response=>{ const g = response.docs.map(doc => ({data: doc.data(), id: doc.id,}))
//         setGame(g)
//         })
//         .catch(error => console.log(error.message));
        
//       }

//     const [modalShow, setModalShow] = useState(false);

//     return (
//         <Fragment>
//             <section className="tf-section live-auctions">
//                 <div className="themesflat-container">
//                     <div className="row">
//                         <div className="col-md-12">
//                             <div className="heading-live-auctions">
//                                 <h2 className="tf-title pb-20">
//                                 Liste des organismes</h2>
//                                 {/* <Link to="/explore-03" className="exp style2">EXPLORE MORE</Link> */}
//                             </div>
//                         </div>
//                         <div className="col-md-12">
//                             <Swiper
//                                 modules={[Navigation, Pagination, Scrollbar, A11y]}
//                                 spaceBetween={30}

//                                 breakpoints={{
//                                     0: {
//                                         slidesPerView: 1,
//                                     },
//                                     767: {
//                                     slidesPerView: 2,
//                                     },
//                                     991: {
//                                     slidesPerView: 3,
//                                     },
//                                     1300: {
//                                         slidesPerView: 4,
//                                     },
//                                 }}
//                                 navigation
//                                 pagination={{ clickable: true }}
//                                 scrollbar={{ draggable: true }}
//                             >
//                                     {Game.slice(0,7).map(gam => (
//                                             <SwiperSlide key={gam.id}>
//                                                     <div className="swiper-container show-shadow carousel auctions">
//                                                         <div className="swiper-wrapper">
//                                                             <div className="swiper-slide">
//                                                                 <div className="slider-item">										
//                                                                     <div className="sc-card-product">
//                                                                         <div className="card-media">
//                                                                             <img src={gam.data.logo} alt="axies" />   
//                                                                         </div>
//                                                                         <div className="card-title">
//                                                                         <h5>{gam.data.nom}</h5> 
//                                                                         </div>
//                                                                         <div className="meta-info">
//                                                                             <div className="author">
                                                                                
//                                                                                 <div className="info">
                                                                                    
//                                                                                     <h6> {gam.data.email}</h6>
//                                                                                     <h6> {gam.data.adresse}</h6>
//                                                                                 </div>
//                                                                             </div>
                                                                            
//                                                                         </div>  
//                                                                     </div>    	
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                             </SwiperSlide>
//                                         ))
//                                     }
//                             </Swiper>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <CardModal
//                 show={modalShow}
//                 onHide={() => setModalShow(false)}
//             />
//         </Fragment>
        
//     );
// }

// ListOrg.propTypes = {
//     data: PropTypes.array.isRequired,
//
// const [dataTab] = useState(
//     [
//         {
//             id: 1,
//             title: "All",
//         },
//         {
//             id: 2,
//             title: "Valide",
//         },
//         {
//             id: 4,
//             title: "Non valide",
//         },
        
//     ]
// )

    const [Game, setGame] = useState([]);
    const [email, getemail]=useState("");
    const form = useRef();
    
    

    useEffect(()=> {
    getGame()
    }, [])

    function getGame(){
        const gameCollectionref = collection(db, 'Organisme')
        getDocs(gameCollectionref).then(response=>{ const g = response.docs.map(doc => ({data: doc.data(), id: doc.id,}))
        setGame(g);
        console.log(g)
        })
        .catch(error => console.log(error.message));
    }


const [visible , setVisible] = useState(8);
const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4);

   
} 



function updateEtat  (id) {
  console.log(id);
      const userDoc = doc(db, "Organisme", id);
      updateDoc(userDoc, {
          isVerified: true
        });
        
        };
       
        var templateParams = {
          name: 'James',
          notes: 'Check this out!'
      }; 

      const fetchUserName = async (id) => {
        try {
          const q = query(collection(db, "Organisme"), where("uid", "===", id));
          const doc = await getDocs(q);
          const data = doc.docs[0].data();
    
          getemail(data.email);
          console.log(data.email);
          
        } catch (error) {
          console.error(error);
        }
      };
    

 async function Email (id) {
    console.log(id);
    await fetchUserName(id);
    console.log(email);
  emailjs.send("service_glpy7r7","template_xfupotz",templateParams, "F3ASCbQUt0DeDKtY_",{
      reply_to: email,
      })
            .then((result) => {
                console.log(result.text);
            }, (error) => {
              console.log(error.text);
            });
        };

      const Update = async (id)=>{
          Email(id);
          updateEtat (id);
          console.log("ok update")
          
          
          
        
      };
   
      function load (){
        
        setTimeout(function(){
            window.location.reload(true);
        console.log("ok loade");
        },5000);
      
    }

    const deleteStart = async (id) => {
      console.log(id);
      const userDoc = doc(db, "Organisme", id);
      await updateDoc(userDoc, {
          activation: false,
          isVerified: false
        });
        
    };
    function load (){
        
        setTimeout(function(){
            window.location.reload(true);
        console.log("ok loade");
        },5000);

    }


const [modalShow, setModalShow] = useState(false);
const [group , getGroup]= useState(0)

const Tabledispatsh = (etat)=>{
  getGroup(etat)
} 

return (
    <Fragment>
        <div className="tf-sectionn sc-explore-2">
            <div className="themesflat-container">
                <div className="row roww">
                    <div className="col-md-12">
                        
                        <div className="flat-tabs explore-tab">
                            <Tabs >
                                {/* <TabList>
                                {
                                        dataTab.map(data=> (
                                            <Tab key={data.id} onClick={()=>Tabledispatsh(data.id)}>{data.title}</Tab>
                                        ))
                                }
                                </TabList> */}
                                {
                                    Game.map(gam =>(
                                        <TabPanel key={gam.data.id} >
                                            { 
                                                
                                                Game.slice(0,visible).map (gam=>{
                                                    if (gam.data.activation.toString() === "true")
                                                    {
                                                    return(
                                                            <div className='sc-card-product'>  
                                                            
                                                                <div key={gam.data.id} className=" explode style2 mg-bt" >
                                                                
                                                                                            
                                                                    <div className="card-media">
                                                                        <img src={gam.data.logo}/>
                                                                                {gam.data.isVerified.toString() === "true"&& 
                                                                                        <div className="button-place-bid">
                                                                                            <button style={{margin:"10px"}} onClick={() => { deleteStart(gam.id); load()}}  >Supprimer l'organisme</button> 

                                                                                        </div>
                                                                                    }
                                                                                    {gam.data.isVerified.toString() === "false" && 
                                                                                        <div className="button-place-bid">
                                                                                            <button style={{margin:"10px"}} onClick={() => { Update(gam.id) ; load ()}}    >Valider</button>
                                                                                            <button style={{margin:"10px"}} onClick={() => { deleteStart(gam.id); load ()}}    >Supprimer l'organisme</button>
                                                                                        
                                                                                        </div>
                                                                                    }

                                                        
                                                                    </div>
                                                                    <div className="card-title">
                                                                                    <h5>{gam.data.nom}</h5> 
                                                                                    </div>
                                                                                        <div className="meta-info">
                                                                                            <div className="author">
                                                                                                
                                                                                                <div className="info">
                                                                                                    
                                                                                                    <h6>Email: {gam.data.email}</h6>
                                                                                                    <h6>Adresse: {gam.data.adress}</h6>
                                                                                                    <h6>Nom de l'organisme: {gam.data.name}</h6>
                                                                                                    <h6>Ville: {gam.data.ville}</h6>
                                                                                                </div>
                                                                                            </div>
                                                                                            
                                                                                    </div>  
                                                                    
                                                                
                                                                </div>
                                                            
                                                                </div> 
                                                       )
                                                    }
                                                    }
                                                      )
                                            
                                              
                                            }
                                            {
                                                visible < Game.length && 
                                                <div className="col-md-12 wrap-inner load-more text-center"> 
                                                    <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Découvrez plus</span></Link>
                                                </div>
                                            }
                                        </TabPanel>
                                    ))
                                }
                            </Tabs>
                                  
                        </div> 
                    </div>   
                </div>
            </div>
        </div>
       
    </Fragment>
);
}


     





export default ListOrg;
