import Login from "./Login";
import AddContributor from "./AddContributor";
import ListeOrganisation from "./ListeOrganisation";
import StartupListV from "./StartupListvalide";


import ChooseOrg from "./ChooseOrg";
import ListeInter from "./ListeInterv";
import UpdateOrg from "./UpdateOrg";
import UpdateInter from "./UpdateInter";

// declarer les route (pages)

const routes = [
   {path: '/', component: <Login />},
  {path: '/AddContributor', component: <AddContributor />},
   {path: '/ListeOrganisation', component: <ListeOrganisation />},
   {path: '/StartupList', component: <StartupListV />},
    {path: '/UpdateOrg', component: <UpdateOrg />},
    {path: '/UpdateInter', component: <UpdateInter />},
   
 {path: '/ListeInter', component: <ListeInter />},
   {path: '/ChooseOrg', component: <ChooseOrg />},

]
export default routes;