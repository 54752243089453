import React from 'react';
import styled from 'styled-components';
import Burger from './Burger';
import logo from "./img/logo.jpeg";

const Nav = styled.nav`
  width: 100%;
  
  border-bottom: 2px solid #f1f1f1;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  background-color: #E7E7E7;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  top: 0;
    left: 0;

  z-index: 9999999999999;

  .logo {
    padding: 15px 0;
  }
`

const Navbar = () => {
  return (
    <Nav>
      <div className="logo">

        <img src={logo} alt="Logo"  style={{width:"10%"}}/>
      </div>
      <Burger />
    </Nav>
  )
}

export default Navbar
