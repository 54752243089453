import React from 'react';
import { Modal } from "react-bootstrap";
import { auth, db, logout } from "../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseButton from 'react-bootstrap/CloseButton';
import { query, collection, getDocs, where ,onSnapshot , doc,getDoc, updateDoc,serverTimestamp, orderBy, limit} from "firebase/firestore";
import ListeInter from '../pages/ListeInterv';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Idiation from './Idiation';


import "../assets/css/geo.css"
import "../assets/css/csspour.scss"
import { set, update } from 'firebase/database';
import { async } from '@firebase/util';
import "../assets/css/style.css"


const CardModal = (props) => {
  const [name, setName] = useState({});
  const navigate = useNavigate();

  //  const fetchUserName = async () => {
  //  try {
  //    const q = query(collection(db, "Startup"), where("uid", "==", props.id));
  //     const doc = await getDocs(q);
  //    const data = doc.docs[0].data();

  //    setName(data)
      
  //  } catch (error) {
  //     console.error(error);
  //    }

  //    console.log(name)
  //  };

  const [nft, setnft]=useState(0);
  const [commerce, getcommerceid]=useState(false);
  const [etudeDeMarche, getetudeDeMarcheid]=useState(false);
  const [financement, getfinancementid]=useState(false);
  const [production, getproductionid]=useState(false);
  const [prototypage, getprototypageid]=useState(false);
  const [ideation, getideation]=useState(false);


  const [etaCommerce, setetaCommerce]=useState(false);
  const [etatEtudeDeMarche, setetatEtudeDeMarche]=useState(false);
  const [etatFinancement, setetatFinancement]=useState(false);
  const [etatProduction, setetatProduction]=useState(false);
  const [etatPrototypage, setetatPrototypage]=useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [id, sendid] = useState("");
 
async function getetatCommerce( idS){
  try {
       const q = query(collection(db, `Startup/${props.id}/commerce/${idS}`));
        const doc = await getDocs(q);
       const data = doc.docs[0].data();
      //  console.log(data)
      //  setetaCommerce(data.isVerified)
        console.log(data.isVerified)
      //  getcommerceid(true)
       return(data.isVerified)
        
     } catch (error) {
        console.error(error);
       }
   
       

 
};
// async function getetatetudeDeMarche(idS){
//   try {
//        const q = query(collection(db, `Startup/${props.id}/etudeDeMarche/${idS}`));
//         const doc = await getDocs(q);
//        const data = doc.docs[0].data();
//        console.log("test"+ data.isVerified)
//        setetatEtudeDeMarche(data.isVerified)
        
//      } catch (error) {
//         console.error(error);
//        }
  
//        console.log("etat E"+etatEtudeDeMarche)

 
// }

// async function getetatfinancement(idS){
//   try {
//        const q = query(collection(db, `Startup/${props.id}/financement/${idS}`));
//         const doc = await getDocs(q);
//        const data = doc.docs[0].data();
//        console.log("test"+ data.isVerified)
//        setetatFinancement(data.isVerified)
        
//      } catch (error) {
//         console.error(error);
//        }
  
//        console.log("etat F"+etatFinancement)

 
// }
// async function getetatproduction(idS){
//   try {
//        const q = query(collection(db, `Startup/${props.id}/production/${idS}`));
//         const doc = await getDocs(q);
//        const data = doc.docs[0].data();
//        console.log(data.isVerified)
//        setetatProduction(data.isVerified)
        
//      } catch (error) {
//         console.error(error);
//        }
  
//        console.log("etat production"+etatProduction)

 
// }
// async function getetatprototypage(idS){
//   try {
//        const q = query(collection(db, `Startup/${props.id}/prototypage/${idS}`));
//         const doc = await getDocs(q);
//        const data = doc.docs[0].data();
//        console.log("test"+ data.isVerified)
//        setetatPrototypage(data.isVerified)
        
//      } catch (error) {
//         console.error(error);
//        }
  
//        console.log("etat prototypage"+etatPrototypage)

 
// }


async function getidRecent(ch) {
  try {
    const response = await fetch(`https://firestore.googleapis.com/v1/projects/startinblock-fd5c0/databases/(default)/documents/Startup/${props.id}/${ch}`);

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    var maxPpg = getMax(result.documents, "updateTime");
     console.log(maxPpg.name)
    

    var tab = maxPpg.name.split("/"); 
    return (tab[tab.length-1])
    
  } catch (err) {
    console.log(err) ;return 0 ;
  }

 
}
const [isValidEtap , getisValidEtap]=useState(false);


async function setisValidEtap  (id, ch) {
  try {
    // const q = query(collection(db, `Startup/${props.id}/${ch}/${id}`));
    const userDoc = doc(db, `Startup/${props.id}/${ch}/${id}`);
    const docSnap = await getDoc(userDoc);
    console.log("Document data:", docSnap.data());
     getisValidEtap(docSnap.data().isVerified);
     console.log(docSnap.data().isVerified)
    // return isValidEtap;
  } catch (error) {
    console.error(error);
  }
  return isValidEtap;
};

//   async function setisValidEtap(id, ch){
//   const gameCollectionref = collection(db, `Startup/${props.id}/${ch}/${id}`)
//   getDocs(gameCollectionref).then(response=>{ const g = response.docs.map(doc => ({data: doc.data(), id: doc.id,}))
//   getisValidEtap(g.data.isVerified);
//   console.log(g)
//   })
//   .catch(error => console.log(error.message));
//   return isValidEtap;

// }
 
   async function CalculerNFT(){
    var f = 0;
    const cat=["commerce","etudeDeMarche","financement", "production", "prototypage", "ideation"]
        for (var i=0 ; i<6 ; i++){
            
          const gameCollectionref = collection(db, `Startup/${props.id}/${cat[i]}`)
          await getDocs(gameCollectionref).then(async response=>{ var g = response.docs.map(doc => ({data: doc.data(), id: doc.id,}))
         
            if(g!=null)
              {
                
               
                
                // console.log(f);
                // console.log(cat[i]);
                // console.log(`Startup/${props.id}/${cat[i]}`);
                const ids= await getidRecent(cat[i])
                
                 console.log(ids)
                 if (ids === 0){
                  return 0
                 }else if( setisValidEtap(ids, cat[i]).toString() ==="true"){
                  f+=1;
                  switch(cat[i]) {
                    case 'commerce':
                      return (getcommerceid(true))
                      
                    case 'etudeDeMarche':
                      return (getetudeDeMarcheid(true))
                      
                    case 'financement':
                      return (getfinancementid(true))
                      
                    case 'production':
                      return (getproductionid(true))
                      
                    case 'prototypage':
                      return (getprototypageid(true))
                      
                    case 'ideation':
                      return(getideation(true))

                    default:
                      return null
                  }
                 }
               


              
              }        
          })
          .catch(error => console.log(error.message));
          
        }
      console.log("f="+f);
     setnft((f/5)*100)
     console.log((f/5)*100);
     console.log(etaCommerce)
     return((f/5)*100); 
}


 
function getMax(arr, prop) {
  var max;
  for (var i=0 ; i<arr.length ; i++) {
      if (max == null ||((arr[i][prop]) > max[prop]))
          max = arr[i];
  }
  
  return max;
};

function update(ch,c){
    const userDoc = doc(db, `Startup/${props.id}/${ch}/${c}`);
    console.log(userDoc)
    updateDoc(userDoc, {
        isVerified: true
      });
    }



async function updateEtat (ch) {
  try {
    const id = await getidRecent(ch);
    console.log(id);
    update(ch,id);
  } catch (err) {
    console.log(err);
  }
};


       

        
     

   
  useEffect(() => {  

  }, []);
  
    
return (


    <Modal
    show={props.show}
    onHide={props.onHide}
    
  >

    <Modal.Header closeButton></Modal.Header>
    <div className="circle-wrap">
        <div className="circle">
         
          <div>
          <CircularProgressbar value={CalculerNFT()} text={`${nft} NFT%`} />
         </div>
          
          
        </div>
      </div>

    <div className="modal-body space-y-20 pd-40" style={{textAlign:"center"}}>
    
   
        <div>
             <h5 className='titre-route'>Feuille de route du {props.nom} </h5>
        </div>
        <div className="route"> 
        {/* <div className="cercle1">
        
        </div> */}
        <div className="cercle2">
          
        
          <p style={{color:"white", margin:"30% 0%" }}>Idéation</p>
          {ideation.toString() == "true" && 
          <div className='cercle2Conf'>
          <div className="button-place-bid">
         
        </div>
        </div>
         }
         </div>
        <div className="cercle3">
        <p style={{color:"white", margin:"30% 0%" }}>Étude de marché</p>
        {etudeDeMarche.toString() == "true" && 
        <div className='cercle3Conf'>
           <div className="button-place-bid ">
           
           {/* <button className="fl-button" onClick={() => {updateEtat("etudeDeMarche");}}><span className='validation'>Validez l'étape d'étude de marché</span></button> */}
         </div>
         </div>
        }
       
        </div>
        <div className="cercle4">
        <p style={{color:"white", margin:"30% 0%" }}>Prototypage</p>
        {prototypage.toString() == "true" &&
        <div className='cercle4Conf'>
            <div className="button-place-bid  ">
          
              {/* <button className="fl-button" onClick={() => {updateEtat("prototypage");}}><span className='validation'>Validez l'étape d'étude de prototypage</span></button> */}
            </div>
        </div>
        }
        </div>
        <div className="cercle5">
        <p style={{color:"white", margin:"30% 0%" }}>Financement</p>
        {financement.toString() == "true" &&
        <div className='cercle5Conf'>
            <div className="button-place-bid  cercle5conf">
            
              {/* <button className="fl-button" onClick={() => {updateEtat("financement");}}><span className='validation'>Validez l'étape de financement</span></button> */}
            </div>
        </div>
        }
        </div>
        <div className="cercle6">
        <p style={{color:"white", margin:"30% 0%" }}>Production</p>
        {production.toString() == "true" &&
        <div className='cercle6Conf'>
            <div className="button-place-bid  cercle6Conf">
           
              {/* <button className="fl-button" onClick={() => {updateEtat("production");}}><span className='validation'>Validez l'étape de production</span></button> */}
            </div>
        </div>
        }
        </div>
        <div className="cercle7">
        <p style={{color:"white", margin:"30% 0%" }}>Commercialisation</p>
        {commerce.toString() == "true" &&
          <div className='cercle7Conf'>
            <div className="button-place-bid">
            
              {/* <button  className="fl-button" onClick={() => {updateEtat("commerce");}}><span className='validation'>Validez l'étape de commercialisation</span></button> */}
            </div>
          </div>
        }
        </div>
        {/* <div className="cercle8"></div> */}
       
        </div>
         
         <div class="flex-container">
         {ideation.toString() == "true" && 
  <div><a href='#' className="fl-button" onClick={() => {setModalShow(true); sendid(props.id)}} ><span className='validation'>Consulter l'étape idéation</span></a></div>
      }
      {etudeDeMarche.toString() == "true" && 
  <div><a className="fl-button" onClick={() => {updateEtat("etudeDeMarche");}}><span className='validation'>Validez l'étape d'étude de marché</span></a></div>
    }
    {prototypage.toString() == "true" && 
  <div><a className="fl-button" onClick={() => {updateEtat("prototypage");}}><span className='validation'>Validez l'étape d'étude de prototypage</span></a></div>  
} {financement.toString() == "true" && 
  <div><a className="fl-button" onClick={() => {updateEtat("financement");}}><span className='validation'>Validez l'étape de financement</span></a></div>
}{production.toString() == "true" && 
  <div><a className="fl-button" onClick={() => {updateEtat("production");}}><span className='validation'>Validez l'étape de production</span></a></div>
}{commerce.toString() == "true" && 
  <div><a  className="fl-button" onClick={() => {updateEtat("commerce");}}><span className='validation'>Validez l'étape de commercialisation</span></a></div>  
} 
</div>
        
        {/* <Link to="/wallet-connect" className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close"> Place a bid</Link>
    */}
    </div> 
    <Idiation
              show={modalShow}
              onHide={() => setModalShow(false)}
              id={id}
              

          />
    </Modal>
    
  );
};

export default CardModal;
