import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth , logInWithEmailAndPassword, signInWithGoogle, erreur} from "../Firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";




function Login() {
  // const [email, setEmail] = useState('');
 // 
  // const [password, setPassword] = useState('');
  //  const [error, setError] = useState('');
  // const navigate = useNavigate();
  // const sign  = UserAuth();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError('')
  //   try {
  //     await sign(email, password)
  //     navigate('/AddOrganization')
  //   } catch (e) {
  //     setError(e.message)
  //     console.log(e.message)
  //   }
  // <form id="contactform" className='login'>
  // };
  //</form> */}
   
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      console.log(loading)
      return;
    }else if(user && user.uid === "GewJ9N5Tk9UpqaNOH3vNkaIwe5n1"){navigate("/ListeOrganisation"); console.log("val")}
  else if (user) {navigate("/AddContributor");}
  }, [user, loading]);

 
  

    return (
<section style={{textAlign: 'left'}} >
            
                        <div >
                                       {/* ref={email}  ref={password} */}
                                    
                                        <div >
                                        
                                        <h5>Se connecter </h5>
                                        <p style={{color:"red", textAlign:"center"}}>{erreur}</p>
                                        </div>
                                        <label>Login</label>
                                        <input value={email} onChange={(e) => setEmail(e.target.value)} id="email" name="email" tabIndex="1" aria-required="true" required type="email" placeholder="Email" />
                                        <label>Mot de passe</label>
                                        <input style={{marginBottom:'20px'}} value={password} onChange={(e) => setPassword(e.target.value)} id="Password" name="password" tabIndex="2"  aria-required="true" type="password" placeholder="Mot de passe" required />
                                        {/* <label className='remember'>Remember me
                                        <input className='checkbox' type="checkbox"/>
                                        </label> */}
                                        {/* <Link to="#" className="remember">Forgot Password?</Link>
                                         */}
                                        <div style={{textAlign:'center'}}>
                                        <button href="" onClick={() => logInWithEmailAndPassword(email, password)} className="submit">Se connecter</button><br></br>
                                        {/* <button className="login__btn login__google" onClick={signInWithGoogle}>Login with Google</button> */}
                                        {/* <Link to="/addStartup" className="remember">Créer un compte Start Up</Link><br></br>
                                        <Link to="/addOrganisme" className="remember">Créer un compte Organisme</Link>
                                        */}
                                        </div> 
                                    
                                </div>

                            

                       
                   
            </section>
    );
  }
  export default Login;