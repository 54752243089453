
import React , { useState , Fragment, useEffect } from 'react';
import { Link , useLocation } from 'react-router-dom';
import DataOrg from '../assets/fake-data/data-live-auction';
// import Header from '../components/HeaderM';
import ListOrg from '../components/ListOrg';
import UpdateInt from '../components/UpdateInt';
import ListeInter from '../components/ListeInter';
import Navbar from '../components/Nav/Navbar';

 

// import "../assets/css/headerStyle.css";
// import '../assets/css/shortcodes.css';
// import '../asset/css/bootstrap.css';



const UpdateInter = ()=> {
    const {state} = useLocation();
    const { id, cat } = state;
    console.log(id)
    console.log(cat)
    


    return (
        <div>
           
        <Navbar />
        <div style={{ backgroundColor:"white" , border_radius:"10px"}}>
        <div style={{padding:"30% 10% 20% 10% "}}><UpdateInt id={id} cat={cat}/></div>

        </div>
        
        
        {/* <ListeInter/> */}
        
        </div>
        
        
    );
  }
  export default UpdateInter;