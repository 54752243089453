import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth , logInWithEmailAndPassword, signInWithGoogle, registerWithEmailAndPassword, db , storage} from "../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

import { ref , uploadBytes } from "firebase/storage";




function ChooseOrg() {
  // const [email, setEmail] = useState('');
 // 
  // const [password, setPassword] = useState('');
  //  const [error, setError] = useState('');
  // const navigate = useNavigate();
  // const sign  = UserAuth();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError('')
  //   try {
  //     await sign(email, password)
  //     navigate('/AddOrganization')
  //   } catch (e) {
  //     setError(e.message)
  //     console.log(e.message)
  //   }
  // <form id="contactform" className='login'>
  // };
  //</form> */}
   
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [ville, setVille] = useState("");
  const [nom, setNom] = useState("");
  const [adress, setAdress] = useState("");
  const [imageUpload, setImageUpload] = useState(null);
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();


 const [Game, setGame] = useState([]);
    
    
    

    useEffect(()=> {
    getGame()
    }, [])

    function getGame(){
        const gameCollectionref = collection(db, 'Organisme')
        getDocs(gameCollectionref).then(response=>{ const g = response.docs.map(doc => ({data: doc.data(), id: doc.id,}))
        setGame(g);
        console.log(g)
        })
        .catch(error => console.log(error.message));
    }
  
  

  useEffect(() => {

    
    if (loading) {

      console.log(email)
      
      return;
    }
    if (user) {
      
    
    navigate("/");
  }
  }, [user, loading]);
//   const content = Game.map((Game) =>{
        
//     return(<>    
//     <select name="ville" id="pet-select" value={ville} onChange={(e) => setVille(e.target.value)}>
//     <option key={Game.id}>{Game.data.name}{Game.data.nom}</option>

// </select></>)
//    });
      

  const Add = async (e) => {
    
    if(imageUpload == null) return;
    const imageRef = ref(storage, `images/logo/${imageUpload.name}`);   
    const logo = `https://firebasestorage.googleapis.com/v0/b/startinblock-fd5c0.appspot.com/o/images%2Flogo%2F${imageUpload.name}?alt=media`;
   uploadBytes(imageRef , imageUpload).then(() => {
     console.log(logo);
     e.preventDefault()
     try {
       registerWithEmailAndPassword(nom, email, password, gender, age, adress, logo, ville);
   
        
       
      
       
     } catch (error) {
       alert(error)
     }     
 });  
      
       
 }
  



  

return (
<section className="tf-login">
                <div>
                    <div className="row">
                        <div >
                                    <div className="flat-form box-login-email">
                                    <div className="form-inner AddStart" >
                                       {/* {error && <p >{error}</p>}   */}
                                       {/* ref={email}  ref={password} */}
                                    
                                        <div className="box-title-login">
                                        <h2 className="titre_1">Startinblock</h2>
                                        <h5>Créer un compte start up</h5>
                                        </div>
                                        <label>Address mail</label>
                                        <input value={email} onChange={(e) => setEmail(e.target.value)} id="email" name="email" tabIndex="1" aria-required="true" required type="email" placeholder="Votre email" />
                                        <label>Mot de passe</label>
                                        <input value={password} onChange={(e) => setPassword(e.target.value)} id="password" name="password" tabIndex="1" aria-required="true" required type="password" placeholder="Votre mot de passe" />
                                        <label>Nom du fondateur</label>
                                        <input value={nom} onChange={(e) => setNom(e.target.value)} id="nom" name="nom" tabIndex="1" aria-required="true" required type="text" placeholder="Nom du fondateur" />
                                        
                                        {/* <label>Verifier votrer mot de passe</label>
                                        <input value={password} onChange={(e) => setPassword(e.target.value)} id="password" name="password" tabIndex="1" aria-required="true" required type="password" placeholder="Confirmer votre mot de passe" /> */}
                                        <label>Age du fondateur</label>
                                        <input value={age} onChange={(e) => setAge(e.target.value)} id="age" name="age" tabIndex="1" aria-required="true" required type="number" placeholder="Age du fondateur" />
                                        <label>Gender du fondateur</label>
                                        <select name="Gender" id="pet-select" value={gender} onChange={(e) => setGender(e.target.value)}>
                                            <option value="Homme">Homme</option>
                                            <option value="Femme">Femme</option>
                                            <option value="Autre">autre</option>
                                        </select>

                                        <label >Logo du Start up (.PNG ou .JPEG)</label>
                                              <label className="uploadFile">
                                              <span className="filename"></span>
                                              <input onChange={(event) => {setImageUpload(event.target.files[0])}}  type="file" className="form-control Addorg" name="file" accept="image/png, image/jpeg"/>
                                          </label><br></br>
                                        <label>Adresse</label>
                                        <input  value={adress} onChange={(event) => {setAdress(event.target.files[0])}} id="Adresse" name="Adresse" tabIndex="2"  aria-required="true" type="text" placeholder="L'address du startup" required />
                                        <select name="ville" id="pet-select" value={ville} onChange={(e) => setVille(e.target.value)}>
                                              <option value="Canada">Canada</option>
                                              <option value="Colombie-Britannique">Colombie-Britannique</option>
                                              <option value="Alberta">Alberta</option>
                                              <option value="Saskatchewan">	Saskatchewan</option>
                                              <option value="Manitoba">Manitoba</option>
                                              <option value="Ontario">Ontario</option>
                                              <option value="Québec">Québec</option>
                                              <option value="Terre-Neuve-et-Labrador">Terre-Neuve-et-Labrador</option>
                                              <option value="Nouveau-Brunswick">Nouveau-Brunswick</option>
                                              <option value="Nouvelle-Écosse">Nouvelle-Écosse</option>
                                              <option value="Île-du-Prince-Édouard">Île-du-Prince-Édouard</option>
                                              <option value="Nunavut">Nunavut</option>
                                              <option value="Territoires du Nord-Ouest">Territoires du Nord-Ouest</option>
                                              <option value="Yukon">Yukon</option>
                                          </select>
                                        {/* {content}                         */}
                                        {/* <label className='remember'>Remember me
                                        <input className='checkbox' type="checkbox"/>
                                        </label> */}
                                        {/* <Link to="#" className="remember">Forgot Password?</Link>
                                         */}
                                        <button onClick={Add} className="submit">Ajouter</button>
                                        <Link to="/" className="remember" >Se connecter</Link>
                                        {/* <button className="login__btn login__google" onClick={signInWithGoogle}>Login with Google</button> */}
                                        
                                    
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
    );
  }
  export default ChooseOrg;