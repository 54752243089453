import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
  } from "firebase/firestore";
// import "../assets/css/Login.css";
import "../assets/css/headerStyle.css";
import { useState, useEffect } from "react";
import { storage , db } from '../../src/Firebase';
import { ref , uploadBytes } from "firebase/storage";
import { v4 } from "uuid";



function Addorg() {
    // const [Organisme, getOrganisme] = useState([]);
    // useEffect(()=> {
    //     getOrganisme()
    // }, [])
    
    // useEffect(()=> {
    //     console.log(Organisme)
    // },[Organisme])

        //    if(imageUpload == null) return;
    //    const imageRef = ref(storage, `images/logo/${imageUpload.name + v4()}`);   logo: imageRef,
    //    uploadBytes(imageRef , imageUpload).then(() => {
    //      alert("image uploded");
    //    });
       
    // await setDoc(doc(db, "Organisme"), {
    //     adresse: newAdress,
    //     email: newEmail,
    //     logo: newEmail,
    //     nom: newName
    // });

   const [newName , setNewName] = useState("");
   const [newAdress, setNewAdress] = useState("");
   const [newEmail, setNewEmail] = useState("");
   const [imageUpload, setImageUpload] = useState(null);



const Add = async (e) => {
    
   if(imageUpload == null) return;
   const imageRef = ref(storage, `images/logo/${imageUpload.name}`);   
   const path = `https://firebasestorage.googleapis.com/v0/b/startinblock-fd5c0.appspot.com/o/images%2Flogo%2F${imageUpload.name}?alt=media`;
  uploadBytes(imageRef , imageUpload).then(() => {
    console.log(path);
});  
    e.preventDefault()
    try {
      await addDoc(collection(db, 'Organisme'), {
        adresse: newAdress, email: newEmail, logo: path, nom: newName
      });
      document.getElementById("AddOrganization").reset();
      
    } catch (err) {
      alert(err)
    }

    
        
      
}


    return (
<section>
        <form id="AddOrganization" className='AddOrganization'>
            <div className="box-title-login">
                <h3 className="titre_2">Ajouter organisme</h3>
            </div>
                <label>Logo de l'organisme(.PNG 250*250) </label>
                 <label className="uploadFile">
                <span className="filename"></span>
                 
                <input onChange={(event) => {setImageUpload(event.target.files[0])}}  type="file"  className="form-control Addorg" name="file" accept="image/png, image/jpeg" />
                 </label>
                <label >Nom de l'Organisme</label>
                <input onChange={(event) => {setNewName(event.target.value);}} id="name"  className="Addorg" name="org_name" tabIndex="1" aria-required="true" required type="text"/>
                <label >Adresse de l'organisme</label>
                <input onChange={(event) => {setNewAdress(event.target.value);}} id="address"  className="Addorg" name="address" tabIndex="1" aria-required="true" required type="text"/>
                <label >Email de l'organisme</label>
                <input onChange={(event) => {setNewEmail(event.target.value);}} id="email"   className="Addorg" name="email" tabIndex="1" aria-required="true" required type="text"/>
                <button className="add" onClick={ Add }>Ajouter</button>
        </form>
        </section>
        );
    }
    export default Addorg;