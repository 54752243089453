import React , { useState , Fragment, useEffect , useRef} from 'react';
import {Link} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import { auth , logInWithEmailAndPassword, signInWithGoogle} from "../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import {storage , db } from '../Firebase';
import "../assets/css/styleUI.css";

import emailjs from '@emailjs/browser';
import { query, collection, getDocs, where, updateDoc ,doc , document, listCollections } from "@firebase/firestore";
// import { concat } from 'ethers/lib/utils';
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Children } from 'react';
import ChildrenList from "./ChildrenList";
// import '../assets/css/style.scss';
// import '../assets/css/headerStyle.css';

import '../assets/css/cssTableau.css';





function ListeInter (){


//   const [idd, setId] = useState("");
//   const navigate = useNavigate();

   const [user, loading, error] = useAuthState(auth);



// // const fetchUserName = async () => {
// //   try {
// //     const q = query(collection(db, "Organisme"), where("uid", "==", user?.uid));
// //     const doc = await getDocs(q);
// //     const data = doc.docs[0].data();

// //     setId(data.uidd);
// //     console.log(data.uidd)

// //   } catch (error) {
// //     console.error(error.message);
// //   }
// // }

// // useEffect(() => {
// //   if (loading) return navigate("/");;
// //   if (!user) return navigate("/");

// //   fetchUserName();
// // }, [user, loading]);








//     const [Commercialisation, setCommercialisation] = useState([]);
//     const [Financement, setFinancement] = useState([]);
//     const [Production, setProduction] = useState([]);
//     const [Prototypage, setPrototypage] = useState([]);
//     const [etudedemarche, setetudedemarche] = useState([]);


//     const [Game, setGame] = useState([]);





// //     const getcom = async () =>{

// //         // try {
// //         //     const q = query(collection(db, `Organisme/${user.uid}`));
// //         //     const doc = await getDocs(q);
// //         //     const data = doc.docs[0].data();


// //         //     console.log(data)

// //         //   } catch (error) {
// //         //     console.error(error);
// //         //   }

// // //         db.collection("Organisme").doc(`${user.uid}`).collection("reviews").get()
// // // .then(querySnapshot => {
// // //     querySnapshot.forEach(doc => {
// // //         console.log(doc.id, " => ", doc.data());
// // //     });
// // // });

// // // const sfRef = db.collection('Organisme').doc(`${user.uid}`);
// // // const collections = await sfRef.listCollections();
// // // collections.forEach(collection => {
// // //   console.log('Found subcollection with id:', collection.id);

// // // });
// // // console.log("collection"+ collection);
// // // const q = collection(db, `Organisme/${user.uid}`);
// // // const docsSnap = await getDocs(q);
// // // //  docsSnap.forEach((doc) => {
// // //      console.log(doc.id, " => ", doc.data());
// // // //    });
// // // console.log(q);
// // // const sfRef = collection(db, `Organisme`).document(`${user.uid}`);
// // // const collections = await sfRef.listCollections();
// // // collections.forEach(collection => {
// // //   console.log('Found subcollection with id:', collection.id);
// // // });
// // const sfRef = db.collection('Organisme').doc(user.uid);
// // const collections = await sfRef.listCollections();
// // collections.forEach(collection => {
// //   console.log('Found subcollection with id:', collection.id);
// // });

// //     }



//     const getF = async ()=>{


//         const cFinancement = collection(db, `Organisme/${user.uid}/Financement`)
//         getDocs(cFinancement).then(response=>{ const f = response.docs.map(doc => ({data: doc.data(), id: doc.id,}))
//        setGame(Game.concat(f));
//         console.log(Game)

//         })
//         .catch(error => console.log(error.message));

//     }

//     const getPro = async ()=>{

//         const colProduction = collection(db, `Organisme/${user.uid}/Production`)
//         getDocs(colProduction).then(response=>{ const c = response.docs.map(doc => ({data: doc.data(), id: doc.id,}))
//         setProduction(c);
//        console.log(c)

//         })
//         .catch(error => console.log(error.message));

//     }

//     const getProt= async ()=>{


//         const colPrototypage = collection(db, `Organisme/${user.uid}/Prototypage`)
//         getDocs(colPrototypage).then(response=>{ const p = response.docs.map(doc => ({data: doc.data(), id: doc.id,}))
//         setPrototypage(p);
//         console.log(p)

//         })
//         .catch(error => console.log(error.message));



//     }


//     const getEtude = async()=>{



//         const coletudedemarche = collection(db, `Organisme/${user.uid}/etudedemarche`)
//         getDocs(coletudedemarche).then(response=>{ const e = response.docs.map(doc => ({data: doc.data(), id: doc.id,}))
//         setetudedemarche(e);
//         console.log(e)


//         })
//         .catch(error => console.log(error.message));

//     }


//     const deleteInt = async (id) => {
//         // const q = query(collection(db, "Organisme"), where("uid", "==", user?.uid));
//         //     const doc = await getDocs(q);
//         //     const data = doc.docs[0].data();
//         const path ="Organisme/"+idd+"/Intervenant/"+id;
//         console.log(path)
//         console.log(id);
//         console.log(user.email)
//          const userDoc = doc(db, "Organisme/"+idd+"/Intervenant/"+id);
//         await updateDoc(userDoc, {
//             etat: false
//           })
//           ;

//       };
//     //   const updtatInt = async (id) => {

//     //     console.log(id);
//     //     console.log(user.email)
//     //     const userDoc = doc(db, "Startup", id);
//     //     await updateDoc(userDoc, {
//     //         activation: false
//     //       }).then(window.location.reload()
//     //       );

//     //   };

//     const [count, setCount] = useState(0);



    // async function concatination (){
    //     // await getEtude();

    //     // await getProt();
    //      await getcom();
    //      await getF();
    //     // await getPro();
    //     // const tab= Commercialisation.concat(Financement,Production,Prototypage,etudedemarche)
    //     // console.log(tab)
    //     // setGame(tab)
    //     console.log("game" +Game)
    // }
    // useEffect(()=> {
    //     fetchUserName();

    //     getF();
    //     getProt();
    //     getcom();
    //     getPro();

    //     }, [])

        const query = collection(db, `Organisme`);
        const [docs, load, errorr] = useCollectionData(query);
       console.log(docs);

//     async function getCollections(db) {
//         // [START firestore_data_get_sub_collections]
//         const sfRef = collection(db, 'Organisme').doc(`${user.uid}`);
//         const collections = await sfRef.listCollections();
//         collections.forEach(collection => {
//           console.log('Found subcollection with id:', collection.id);
//         });
//         // [END firestore_data_get_sub_collections]
//       }

//         useEffect(async () => {
//             if (loading) return {};
//             if (!user) return navigate("/");

//            console.log(user.uid);
//             // fetchUserName();
//             test();


//           }, [user, loading]);

// const [visible , setVisible] = useState(8);
// const showMoreItems = () => {
//     setVisible((prevValue) => prevValue + 4);


// }





// function updateEtat  (id) {
//   console.log(id);
//       const userDoc = doc(db, "Organisme", id);
//       updateDoc(userDoc, {
//           isVerified: true
//         });

//         };

//         var templateParams = {
//           name: 'James',
//           notes: 'Check this out!'
//       };

//  function Email (id) {

//   emailjs.send("service_glpy7r7","template_xfupotz",templateParams, "F3ASCbQUt0DeDKtY_",{
//       reply_to: id.Email,
//       })
//             .then((result) => {
//                 console.log(result.text);
//             }, (error) => {
//               console.log(error.text);
//             });
//         };

//       const Update = async (id)=>{
//           updateEtat (id);
//           Email (id);

//       }





//     const deleteStart = async (id) => {
//       console.log(id);
//       const userDoc = doc(db, "Organisme", id);
//       await updateDoc(userDoc, {
//           activation: false
//         });

//     };


// const [modalShow, setModalShow] = useState(false);
// const [group , getGroup]= useState(0)

// const Tabledispatsh = (etat)=>{
//   getGroup(etat)
//}
const cat=["commerce","etudeDeMarche","financement", "production", "prototypage"]



return (


<section style={{    marginTop: "40%"}}>


{loading && "Loading..."}
{/* style={{margin:"2%",marginTop: "20%"}} */}
<ul >
  {docs?.map((doc) => (
    <div key={Math.random()} >
      {doc.email===user.email &&

      <><><ChildrenList path={`Organisme/${user.uid}/Commercialisation`} />
          <ChildrenList path={`Organisme/${user.uid}/Financement`} />
          <ChildrenList path={`Organisme/${user.uid}/Production`} />
          <ChildrenList path={`Organisme/${user.uid}/Prototypage`} />
          <ChildrenList path={`Organisme/${user.uid}/etudedemarche`} /></></>
}
    </div>
  ))}

</ul>

</section>

);


  }

export default ListeInter;
