import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth , logInWithEmailAndPassword, signInWithGoogle, registerWithEmailAndPassword, db , storage, registerWithEmailAndPasswordTest} from "../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,  where
} from "firebase/firestore";

import { ref , uploadBytes } from "firebase/storage";





function CreeStart() {
  // const [email, setEmail] = useState('');
 // 
  // const [password, setPassword] = useState('');
  //  const [error, setError] = useState('');
  // const navigate = useNavigate();
  // const sign  = UserAuth();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError('')
  //   try {
  //     await sign(email, password)
  //     navigate('/AddOrganization')
  //   } catch (e) {
  //     setError(e.message)
  //     console.log(e.message)
  //   }
  // <form id="contactform" className='login'>
  // };
  //</form> */}
   
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [ville, setVille] = useState("");
  const [nom, setNom] = useState("");
  const [adress, setAdress] = useState("");
  const [Organisme, setOrg] = useState("");
  const [imageUpload, setImageUpload] = useState(null);
  const [user, loading, error] = useAuthState(auth);
  const [validation , setValidation] =useState("");
  const navigate = useNavigate();


 const [Game, setGame] = useState([]);
    
    
    


    function getGame(){
        const gameCollectionref = collection(db, 'Organisme')
        getDocs(gameCollectionref).then(response=>{ const g = response.docs.map(doc => ({data: doc.data(), id: doc.id,}))
        setGame(g);
        console.log(g)
        })
        .catch(error => console.log(error.message));
    }
  
  

  useEffect(() => {

    
    if (loading) {

      console.log(email)
      
      return;
    }
    if (user) {
      
    
    navigate("/");
  }
  getGame()
  }, [user, loading]);
  
//   const content = Game.map((Game) =>{
//         if (Game.data.ville === ville){
//    return(<>  
   
//    <select  value={Organisme} id="pet-select" onChange={(e) => setOrg(e.target.value)}>
   
//     <option value={Organisme}  key={Game.id}>{Game.data.name}</option>
   
// </select>

// </>)}
//   });

  
function content (ville) {
  console.log(ville)
 const t = <select  value={Organisme} id="pet-select" onChange={(e) => setOrg(e.target.value)}>
  {Game.map((Game) =>{
    console.log(Game.data.ville === ville)
    console.log(ville)
    console.log(Game.data.name)
        if (Game.data.ville === ville)
        
        {<option value={Game.data.name}  key={Game.data.id}>{Game.data.name}</option>}
        
  })
  }



</select>;
console.log(Organisme)
return t

}
const arr = [
  {value: '', text: '--Choose an option--'},
  {value: 'apple', text: 'Apple 🍏'},
  {value: 'banana', text: 'Banana 🍌'},
  {value: 'kiwi', text: 'Kiwi 🥝'},
];

const handleChange = event => {
  console.log(event.target.value);
};
// const App = () => {


//   return (
//     <div>
//       <select onChange={handleChange} name="fruits" id="fruit-select">
//         {arr.map((option, index) => (
//           <option key={index} value={option.value}>
//             {option.text}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

const [name, setName] = useState("");

  async function fetchUserName  (id)  {
  try {
    const q = query(collection(db, "Organisme"), where("uid", "==", id));
    const doc = await getDocs(q);
    const data = doc.docs[0].data();

    setName(data.name);
    console.log(data.name)
    
  } catch (error) {
    console.error(error);
  }
};



const [erreur, seterror] = useState("");

  const Add = async (e) => {

    
    if(imageUpload == null ||nom =="" || email =="" || password =="" ||   adress =="" || ville =="" || Organisme=="" || age=="") 
    {setValidation("");
      return (seterror("Veuillez remplire le formulaire correctement!") );}

    const imageRef = ref(storage, `images/logo/${imageUpload.name}`);   
    const logo = `https://firebasestorage.googleapis.com/v0/b/startinblock-fd5c0.appspot.com/o/images%2Flogo%2F${imageUpload.name}?alt=media`;
   uploadBytes(imageRef , imageUpload).then(() => {
     console.log(logo);
     e.preventDefault();
     setGender("homme");
     try { 
      // name, email, password, gender, age, adress, logo, ville, Organisme
      registerWithEmailAndPasswordTest(nom, email, password, gender, age, adress, logo, ville, Organisme);
   
        console.log("c'est bon");
       
       setValidation("Startup créer");
       seterror("");
       
     } catch (error) {
       alert(error)
     }     
 });  
      
       
 }
  

  // const imageRef = ref(storage, `images/logo/${imageUpload.name}`);   
  // const logo = `https://firebasestorage.googleapis.com/v0/b/startinblock-fd5c0.appspot.com/o/images%2Flogo%2F${imageUpload.name}?alt=media`;
  //   uploadBytes(imageRef , imageUpload).then(() => {
  //   console.log(logo);

  //   }); 

  

    return (
<section style={{textAlign: 'left'}}>
                

                                       {/* {error && <p >{error}</p>}   */}
                                       {/* ref={email}  ref={password} */}
                                    
                                        <div >
                                        
                                        <h5>Créer un compte</h5>
                                        <p style={{color: "red", textAlign:"center"}}>{erreur}</p>
                                        <p style={{color: "green", textAlign:"center"}}>{validation}</p>
                                        </div>
                                        <label>Address mail</label>
                                        <input value={email} onChange={(e) => setEmail(e.target.value)} id="email" name="email" tabIndex="1" aria-required="true" required type="email" placeholder="Votre email" />
                                        <label>Mot de passe</label>
                                        <input value={password} onChange={(e) => setPassword(e.target.value)} id="password" name="password" tabIndex="1" aria-required="true" required type="password" placeholder="Votre mot de passe" />
                                        <label>Nom du fondateur</label>
                                        <input value={nom} onChange={(e) => setNom(e.target.value)} id="nom" name="nom" tabIndex="1" aria-required="true" required type="text" placeholder="Nom du fondateur" />
                                        
                                        {/* <label>Verifier votrer mot de passe</label>
                                        <input value={password} onChange={(e) => setPassword(e.target.value)} id="password" name="password" tabIndex="1" aria-required="true" required type="password" placeholder="Confirmer votre mot de passe" /> */}
                                        <label>Age du fondateur</label>
                                        <input value={age} onChange={(e) => setAge(e.target.value)} id="age" name="age" tabIndex="1" aria-required="true" required type="number" placeholder="Age du fondateur" />
                                        <label style={{marginRight:'10px'}}>Gender du fondateur</label>
                                        <select name="Gender" id="pet-select" value={gender} onChange={(e) => setGender(e.target.value)}>
                                            <option value="homme">Homme</option>
                                            <option value="femme">Femme</option>
                                            <option value="autre">Autre</option>
                                        </select>
                                        <br></br>
                                        <label >Logo du Start up (.PNG)</label>
                                              <label className="uploadFile">
                                              <span className="filename"></span>
                                              <input onChange={(event) => {setImageUpload(event.target.files[0])}}  type="file" className="form-control Addorg" name="file" accept="image/png, image/jpeg"/>
                                          </label><br></br>
                                          <label>Adresse du startup</label>
                                          <input  value={adress} onChange={(e) => {setAdress(e.target.value)}} id="Adresse" name="Adresse" tabIndex="2"  aria-required="true" type="text" placeholder="Adresse du startup" required />
                                         <label style={{marginRight:'10px'}}>Province</label>
                                        <select name="ville" id="pet-select" value={ville} onChange={(e) => {setVille(e.target.value); setOrg("")}}>
                                              <option valut="">--Choisire une Province--</option>
                                              <option value="Canada">Canada</option>
                                              <option value="Colombie-Britannique">Colombie-Britannique</option>
                                              <option value="Alberta">Alberta</option>
                                              <option value="Saskatchewan">	Saskatchewan</option>
                                              <option value="Manitoba">Manitoba</option>
                                              <option value="Ontario">Ontario</option>
                                              <option value="Québec">Québec</option>
                                              <option value="Terre-Neuve-et-Labrador">Terre-Neuve-et-Labrador</option>
                                              <option value="Nouveau-Brunswick">Nouveau-Brunswick</option>
                                              <option value="Nouvelle-Écosse">Nouvelle-Écosse</option>
                                              <option value="Île-du-Prince-Édouard">Île-du-Prince-Édouard</option>
                                              <option value="Nunavut">Nunavut</option>
                                              <option value="Territoires du Nord-Ouest">Territoires du Nord-Ouest</option>
                                              <option value="Yukon">Yukon</option>
                                              <option value="Tunis">Tunis</option>
                                          </select>
                                         
                                         
                                           {/* <select name="ville" id="pet-select" value={Organisme} onChange={(e) => setOrg(e.target.value)}>
                                            {
                                            Game.map(Game, i) =>{
                                              if ({Game.data.ville} == ville)
                                              {return <option value={Organisme} key={i}>{Game.data.ville}</option>;}
                                            }
                                            return null;
                                            }
                                              
                                              
                                          </select> */}
                                         
                                          <br></br>
                                         
                                         <label style={{marginRight:'10px'}}>Organisme</label>
                                         
                                          
                                          <select value={Organisme} onChange={(e) => {setOrg(e.target.value); fetchUserName  (e.target.value)} } name="fruits" id="fruit-select" style={{marginBottom:'10px', marginLeft:'10px'}}>
                                          <option value="">--Choisire un organisme--</option>
                                            {Game.map((game) => {
                                              console.log (game.data.ville)
                                              if(game.data.ville === ville) {
                                                
                                                return (<option key={game.id} value={game.id}>{game.data.name} </option> )
                                               
                                             
                                              
                                            }
                                            
                                            

                                          
                                            return null})
                                            }
                                             
                                       
                                          </select>
                                          <br></br>
                                          {Organisme !== "" &&
                                          
                                          <label style={{marginRight:'10px'}}>Organisme choisie est {name}</label>
                                          }
                                          
                                         
                                      
                                        {/* {content}                         */}
                                        {/* <label className='remember'>Remember me
                                        <input className='checkbox' type="checkbox"/>
                                        </label> */}
                                        {/* <Link to="#" className="remember">Forgot Password?</Link>
                                         */}
                                         <div style={{textAlign:'center'}}>
                                            
                                        <button onClick={Add} className="submit" style={{textAlign:'center'}}>Ajouter</button><br></br>
                                        {/* <Link to="/" className="remember" style={{textAlign:'center'}}>Se connecter</Link> */}
                                        </div>
                                        {/* <button className="login__btn login__google" onClick={signInWithGoogle}>Login with Google</button> */}
                                        
                                    
                                
                             

                        
            </section>
    );
  }
  export default CreeStart;