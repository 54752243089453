import React , { useState , Fragment, useEffect , useRef} from 'react';
import {Link} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CardModal from './CardModal';
import {storage , db, auth } from '../../src/Firebase';
import { getDatabase, ref, query, orderByChild } from "firebase/database";
import {collection, getDocs, orderBy ,updateDoc ,doc, deleteDoc, setDoc, where} from 'firebase/firestore';
import emailjs from '@emailjs/browser';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";



// import '../assets/css/style.css';
// import '../assets/css/shortcodes.css';
// import '../assets/css/bootstrap.css';




const ListeSatrtup = props =>{
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [dataTab] = useState(
      [
          {
              id: 1,
              title: "All",
          },
          {
              id: 2,
              title: "Valide",
          },
          {
              id: 4,
              title: "Non valide",
          },
          
      ]
  )
 
      const [Game, setGame] = useState([]);
      const [userid, setuserId]=useState("");
      const [email, getemail]=useState("");
      const form = useRef();
      
      
  
      function updateEtat  (id) {
        console.log(id);
            const userDoc = doc(db, "Startup", id);
            console.log(userDoc)
            updateDoc(userDoc, {
                isVerified: true
              });
              
              
              };
             
              var templateParams = {
                name: 'James',
                notes: 'Check this out!'
            }; 

            
  const fetchUserName = async (id) => {
    try {
      const q = query(collection(db, "Startup"), where("uid", "===", id));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      getemail(data.email);
      console.log(data.email)
      
    } catch (error) {
      console.error(error);
    }
  };
  const [idd, setId] = useState("");
  const fetchUser = async () => {
    try {
      const q = query(collection(db, "Organisme"), where("uidd", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setId(data.uidd);
      console.log(data.uidd)
      
    } catch (error) {
      console.error(error);
    }
  };

    
       function Email (id) {
        fetchUserName(id);
        emailjs.send("service_glpy7r7","template_xfupotz",templateParams, "F3ASCbQUt0DeDKtY_",{
            reply_to: email,
            })
                  .then((result) => {
                      console.log(result.text);
                  }, (error) => {
                    console.log(error.text);
                  });
              };
    
            const Update = async (id)=>{
                Email (id);
                updateEtat (id);
                
                
              
            }
              
    
            
        
    
          const deleteStart = async (id) => {
            console.log(id);
            const userDoc = doc(db, "Startup", id);
            await updateDoc(userDoc, {
                activation: false
              });
              
          };
  
      function getGame(){
          const gameCollectionref = collection(db, 'Startup')
          getDocs(gameCollectionref).then(response=>{ const g = response.docs.map(doc => ({data: doc.data(), id: doc.id,}))
          setGame(g);
          console.log(g)
          
          })
          .catch(error => console.log(error.message));
          

      }
      function load (){
        
        setTimeout(function(){
            window.location.reload(true);
        console.log("ok loade");
        },5000);

    }
      
    useEffect(() => {
        if (loading) return navigate("/StartupList");;
        if (!user) return navigate("/");
    
        fetchUserName();
        fetchUser();
         getGame();
    
    
     
      
      }, [user, loading]);


  const [visible , setVisible] = useState(30);
  const showMoreItems = () => {
      setVisible((prevValue) => prevValue + 4);

     
  } 

  



  const [modalShow, setModalShow] = useState(false);
  const [id, sendid] = useState("");
  const [noms, sendNom] = useState("");

//   const [idd, setId] = useState(0);
  const [group , getGroup]= useState(0)
  
  const Tabledispatsh = (etat)=>{
    getGroup(etat)
  } 

  return (
    
      <Fragment>
          <div className="tf-section sc-explore-2">
          
              <div className="themesflat-container">
              
                  <div className="row">
                  
                      <div className="col-md-12">
                      
                          
                          <div className="flat-tabs explore-tab">
                              <Tabs >
                                  {/* <TabList>
                                  {
                                          dataTab.map(data=> (
                                              <Tab key={data.id} onClick={()=>Tabledispatsh(data.id)}>{data.title}</Tab>
                                          ))
                                  }
                                  </TabList> */}
                                  {
                                      Game.map(gam =>(
                                          <TabPanel key={gam.data.id} >
                                              { 
                                                  
                                                  Game.slice(0,visible).map (gam=>{
                                                    if (gam.data.activation.toString() === "true"  && user.uid===gam.data.OrganismeId ){
                                                    return(
                                                            <div className='sc-card-product'>  
                                                            
                                                            <div key={gam.data.id} className=" explode style2 mg-bt" >
                                                            
                                                                                            
                                                                <div className="card-media">
                                                                    <img src={gam.data.logo}/>
                                                                    
                                                                    {gam.data.isVerified.toString() === "true"&&
                                                                        <div className="button-place-bid">
                                                                            <button style={{margin:"10px"}} onClick={() => {setModalShow(true); sendid(gam.id); sendNom(gam.data.name)}} ><span>Découvrez la feuille de route</span></button>
                                                                            <button style={{margin:"10px"}} onClick={() => {deleteStart(gam.id);load ()}}    >Supprimer la Startup</button>

                                                                        </div>
                                                                    }
                                                                    {gam.data.isVerified.toString() === "false" && 
                                                                        <div className="button-place-bid">
                                                                            <button style={{margin:"10px"}} onClick={() => {Update(gam.id);load ()}}   >Valider</button>
                                                                            <button style={{margin:"10px"}} onClick={() => {deleteStart(gam.id);load ()}}    >Supprimer la Startup</button>
                                                                        
                                                                        </div>
                                                                    }
                                                                        
                                                                </div>
                                                                <div className="card-title">
                                                                    <h6><strong>Nom du respensable du startup:</strong> {gam.data.name}</h6>  
                                                                </div>
                                                                <div className="meta-info">
                                                                    <div className="author">
                                                                        
                                                                        <div className="info">
                                                                        <h6><strong>Adress mail:</strong> {gam.data.email}</h6> 
                                                                        <h6><strong>Adress:</strong> {gam.data.adress}</h6>
                                                                        <h6><strong>Ville:</strong> {gam.data.ville}</h6>
                                                                        
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                
                                                            </div>
                                                            
                                                            </div> 
                                                  )
                                                }
                                                }
                                                  )
                                                
                                              }
                                              {
                                                  visible < Game.length && 
                                                  <div className="col-md-12 wrap-inner load-more text-center"> 
                                                      <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Découvrez plus</span></Link>
                                                  </div>
                                              }
                                          </TabPanel>
                                      ))
                                  }
                              </Tabs>
                                         {/* <div>
                                        <form ref={form} onSubmit={sendEmail}>
                                        <label>Name</label>
                                        <input type="text" name="user_name" />
                                        <label>Email</label>
                                        <input type="email" name="user_email" />
                                        <label>Message</label>
                                        <textarea name="message" />
                                        <input type="submit" value="Send" />
                                        </form>
                                        </div>  */}
                          </div> 
                      </div>   
                  </div>
              </div>
          </div>
          <CardModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              id={id}
              nom={noms}

          />
      </Fragment>
  );
}


                        
                        

export default ListeSatrtup;
