import React from 'react';

import DataOrg from '../assets/fake-data/data-live-auction';
import Header from '../components/HeaderM';
import UpdateOrgg from '../components/UpdateOrg'; 
import Navbar from '../components/Nav/Navbar';


//  import '../assets/css/style.css';
// import '../assets/css/shortcodes.css';
// import "../assets/css/headerStyle.css";






const UpdateOrg= ()=> {
  

    return (
        <div>
         
          <Navbar />   
        {/* <Addorg /> */}
        <div style={{backgroundColor:"white", padding:"10%" }}>
               
          <UpdateOrgg />   
          </div>
          
        
       
        </div>
        
        
    );
  }
  export default UpdateOrg;