import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth , logInWithEmailAndPassword, signInWithGoogle} from "../Firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import "../assets/css/styleUI.css";
import CreeStart from "../components/CreeStart";
import CreeOrg from "../components/CreeOrg";
import Loginn from "../components/Login";
// import { async } from "@firebase/util";
import logoS from "../assets/img/logoNoire.png";
import logoN from "../assets/img/LOGO-net.png";
import logo from "../assets/img/logo.jpeg";
import logoD from "../assets/img/digihub.png";
import { async } from "@firebase/util";
//import "../assets/js/script";
// import '@font-awesome/5.14.0/css/all.min.css';






function Login() {
  const [boutonCO, getboutonO]=useState(0);
  const [boutonCs, getboutons]=useState(0);

async function bouton (){
  
    
    const signUpButtonS = document.getElementById('signUpS');
  
  const container = document.getElementById('container');
  console.log(boutonCO);
    console.log(boutonCs);
    getboutonO(0);
    getboutons(1);
    console.log(boutonCO);
    console.log(boutonCs);
    container.classList.add("right-panel-active");

  
  
}


 async function bouton1(){
  const signInButton = document.getElementById('signIn');
  const container = document.getElementById('container');
  container.classList.remove("right-panel-active");
 
  
}
 function bouton2 (ch){

  

  const signUpButtonO = document.getElementById(ch);

const container = document.getElementById('container');
container.classList.add("right-panel-active");



}




    return (
 <section>

<div className="container" id="container">
	<div className="form-container sign-up-container">
		 <div className="form">
      
			 { boutonCs === 1 && 
       
      <CreeStart />
        } { boutonCO === 1 &&
        <CreeOrg />
         } 
		</div> 
    

	</div>
	 <div className="form-container sign-in-container">
   <div  className="form">
   <img src={logo} alt="Logo" style={{width: '50%', marginBottom:"10px"}}/>
			
      <Loginn />
      <div style={{marginTop:"20%"}}>
        <img src={logoS} alt="Logo" style={{width: '20%', marginRight:"10%"}}/>
      <img src={logoN} alt="Logo" style={{width: '20%', marginLeft:"10%", marginRight:"10%"}}/>
      <img src={logoD} alt="Logo" style={{width: '20%', marginLeft:"10%"}}/>
      </div>
      
		</div>
    
	</div> 
 
	<div className="overlay-container">
		<div className="overlay">
			<div className="overlay-panel overlay-left">
      
				<h1>BONJOUR!</h1>
				<p>Creer un compte StartUp ou Organisme sur StartInBlock</p>
				<button className="ghost" id="signIn" onClick={bouton1} style={{marginBottom:"10px"}}>Se connecter</button>
        {/* <button className="ghost" id="signUpO" onClick={bouton2}>Créer un compte Organisme</button> */}

			</div>
			<div className="overlay-panel overlay-right">
				<h1>BONJOUR!</h1>
				<p>Connectez vous sur votre espace administarteur ou organisme sur StartInBlock</p>
				<button className="ghost" id="signUpO" onClick={() => {getboutonO(1); getboutons(0); bouton2("signUpO")}} style={{marginBottom:"10px"}}>Créer un compte Organisme</button>
        <button className="ghost" id="signUpS" onClick={bouton}>Créer un compte Start Up</button>
			</div>
      
		</div>
	</div>
</div>

{/* <footer>
	<p>
		Created with <i className="fa fa-heart"></i> by
		<a target="_blank" href="https://florin-pop.com">Florin Pop</a>
		- Read how I created this and how you can join the challenge
		<a target="_blank" href="https://www.florin-pop.com/blog/2019/03/double-slider-sign-in-up-form/">here</a>.
	</p>
</footer> */}
{/* <script crossOrigin src="../assets/js/script.js"></script> */}
            </section> 
            


    );
  }
  export default Login;