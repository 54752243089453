import React from 'react';

import DataOrg from '../assets/fake-data/data-live-auction';
import Header from '../components/HeaderM';
import List from '../components/ListeSatrtup';
import Navbar from '../components/Nav/Navbar';


// import '../assets/css/style.css';
// import '../assets/css/shortcodes.css';






const StartupListvalide= ()=> {
  

    return (
        <div>
          
          <Navbar />   
        {/* <Addorg /> */}
        <div >
            
         <List data={DataOrg} /> 
        </div>
       
        </div>
        
        
    );
  }
  export default StartupListvalide;