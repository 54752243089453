
import React , { useState , Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataOrg from '../assets/fake-data/data-live-auction'; import Header from '../components/HeaderM';
import ListOrg from '../components/ListOrg';
import AddCon from "../components/Addcon";
import ListeInter from '../components/ListeInter';
// import Header from '../components/Navbar';
import Navbar from '../components/Nav/Navbar';
// import "../assets/css/headerStyle.css";
// import '../assets/css/shortcodes.css';
// import '../asset/css/bootstrap.css';



const AddContributor= ()=> {
    
   
    return (
        <div>
            
         <Navbar /> 
        <div  >
        <div style={{padding:"10% 10%"}}><AddCon /></div>

        </div>
        
        
        {/* <ListeInter/> */}
        </div>
        
        
    );
  }
  export default AddContributor;