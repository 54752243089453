import React from 'react';
import { Link } from 'react-router-dom';
import DataOrg from '../assets/fake-data/data-live-auction';
import Header from '../components/Header';
import ListOrg from '../components/ListOrg';
import Addorg from "../components/Addorg";
import Navbar from '../components/Navv/Navbar';

// import '../assets/css/style.css';
// import '../assets/css/shortcodes.css';
// import '../asset/css/bootstrap.css';


const ListeOrganisation= ()=> {
  

    return (
        <div>
       
        <Navbar />
        
        {/* <Addorg /> */}
         <div >
            <ListOrg data={DataOrg} /> 
        </div> 
        </div>
        
        
    );
  }
  export default ListeOrganisation;