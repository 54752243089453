import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth , logInWithEmailAndPassword, signInWithGoogle, registerWithEmailAndPassword, db , storage, registerWithEmailAndPasswordOrg, logout} from "../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {signOut} from "firebase/auth"
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  where,
  query,
} from "firebase/firestore";

import { ref , uploadBytes } from "firebase/storage";
// import "../assets/css/headerStyle.css";

// import '../assets/css/style.css';
// import '../assets/css/shortcodes.css';
// import '../asset/css/bootstrap.css';


const UpdateOrg= ()=> {
   
  
    const [nom, setNom] = useState("");
    const [adress, setAdress] = useState("");
    const [ville , setVille] = useState("");
    const [email , setEmail] = useState("");
    const [erreur, seterror] = useState("");
    const [erreur1, seterror1] = useState("");
    const [erreur2, seterror2] = useState("");
    const [erreur3, seterror3] = useState("");
    const [erreur4, seterror4] = useState("");
    const [erreur5, seterror5] = useState("");
    const [erreur6, seterror6] = useState("");
    const [erreur7, seterror7] = useState("");
    const [erreur8, seterror8] = useState("");
    const [erreur9, seterror9] = useState("");
    const [oldname, setNameOld]= useState("");
    const [oldadress, setAdressOld]= useState("");
    const [oldville, setvilleOld]= useState("");
    const [validation, setValider]=useState("");

    const [imageUpload, setImageUpload] = useState(null);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
  
  
   
    function load (){
        
        setTimeout(function(){
            window.location.reload(true);
        console.log("ok loade");
        },5000);
    
    }

    const fetchOrg = async () => {
        try {
          const q = query(collection(db, "Organisme"), where("uid", "==", user?.uid));
          const doc = await getDocs(q);
          const data = doc.docs[0].data();
    
          setEmail(data.email);
          setNameOld(data.name);
          setAdressOld(data.adress);
          setvilleOld(data.uidd);
          
          console.log(data.uidd)
          
        } catch (error) {
          console.error(error);
        }
      };


  useEffect(() => {

    
    if (loading) {

      
      
      return;
    }
    if (user) {
      
    
    
    fetchOrg();
  }
  }, [user, loading]);

  

  const update = async (e) => {
    
    if(nom =="" &&   adress =="" && ville =="" && imageUpload == null) 
    return (seterror("Vous n'avez rien modifier")) 
    
   
    const imageRef = ref(storage, `images/logo/${imageUpload.name}`);   
    const logo = `https://firebasestorage.googleapis.com/v0/b/startinblock-fd5c0.appspot.com/o/images%2Flogo%2F${imageUpload.name}?alt=media`;
   uploadBytes(imageRef , imageUpload).then(async () => {
     console.log(logo);
     e.preventDefault()
     try {
      
      const userDoc = doc(db, "Organisme", user?.uid);
      await updateDoc(userDoc, {
      name: nom,
      adress: adress,
      logo: logo,
      ville: ville,

          }).then(seterror(""),setValider("Vos informations ont été modifier") , load());
       
       
     } catch (error) {
       alert(error)
     }     
 });  


 
    
          
       
      
       
 }
  
      return (
  <section >
                  
                                      
                                         {/* ref={email}  ref={password} */}
                                         
                                          <div >
                                          
                                          <h5>Modifier votre Compte organisme</h5>
                                          <p style={{color: "red", textAlign:"center"}}>{erreur}</p>
                                          <p style={{color: "green", textAlign:"center"}}>{validation}</p>
                                          </div>
                                          <label>Logo de l'Organisme(.PNG 250*250)</label>
                                            <label className="uploadFile">
                                            <span className="filename"></span>
                                            <p style={{color: "red"}}>{erreur1}</p>
                                            
                                            <input onChange={(event) => {setImageUpload(event.target.files[0])}}  type="file"  className="form-control Addorg" name="file" accept="image/png, image/jpeg" />
                                            </label>
                                            <br></br>
                                          <p style={{color: "red"}}>{erreur4}</p>
                                          <label>Nom de l'Organisme</label>
                                          <input  onChange={(e) => setNom(e.target.value)} id="nom" name="nom" tabIndex="1" aria-required="true" required type="text" defaultValue={oldname} />
                                          <p style={{color: "red"}}>{erreur2}</p>
                                          {/* <label>Verifier votrer mot de passe</label>
                                          <input value={password} onChange={(e) => setPassword(e.target.value)} id="password" name="password" tabIndex="1" aria-required="true" required type="password" placeholder="Confirmer votre mot de passe" /> */}
                                          
  
                                          
                                          <label>Adresse de l'organisme</label>
                                          <input  onChange={(e) => {setAdress(e.target.value)}} id="Adresse" name="Adresse" tabIndex="2"  aria-required="true" type="text" defaultValue={oldadress} required />
                                          <p style={{color: "red"}}>{erreur7}</p>
                                          <label style={{marginRight:'10px'}}>Ville {oldville}</label>
                                          <select name="ville" id="pet-select" defaultValue={oldville} onChange={(e) => setVille(e.target.value)}>
                                          <option valut="">--Choisire une ville--</option>
                                              <option value="Canada">Canada</option>
                                              <option value="Colombie-Britannique">Colombie-Britannique</option>
                                              <option value="Alberta">Alberta</option>
                                              <option value="Saskatchewan">	Saskatchewan</option>
                                              <option value="Manitoba">Manitoba</option>
                                              <option value="Ontario">Ontario</option>
                                              <option value="Québec">Québec</option>
                                              <option value="Terre-Neuve-et-Labrador">Terre-Neuve-et-Labrador</option>
                                              <option value="Nouveau-Brunswick">Nouveau-Brunswick</option>
                                              <option value="Nouvelle-Écosse">Nouvelle-Écosse</option>
                                              <option value="Île-du-Prince-Édouard">Île-du-Prince-Édouard</option>
                                              <option value="Nunavut">Nunavut</option>
                                              <option value="Territoires du Nord-Ouest">Territoires du Nord-Ouest</option>
                                              <option value="Yukon">Yukon</option>
                                              <option value="Tunis">Tunis</option>
                                          </select>
                                          <p style={{color: "red"}}>{erreur8}</p>
                                          {/* <label className='remember'>Remember me
                                          <input className='checkbox' type="checkbox"/>
                                          </label> */}
                                          {/* <Link to="#" className="remember">Forgot Password?</Link>
                                           */}
                                           <div style={{textAlign:'center'}}>
                                          <button onClick={ update } className="submit">Modifier</button>
                                          </div>
                                          {/* <Link to="/" className="remember" >Se connecter</Link> */}
                                          {/* <button className="login__btn login__google" onClick={signInWithGoogle}>Login with Google</button> */}
                                          
                                      
  
                             
  
                      
              </section>
      );
   }
  export default UpdateOrg;