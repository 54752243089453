
import React , { useState , Fragment, useEffect } from 'react';
import { db , storage, auth } from '../Firebase';
import { query, collection, getDocs, where, addDoc, updateDoc, doc, setDoc} from "firebase/firestore";

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import { ref , uploadBytes } from "firebase/storage";

// import "../assets/css/Login.css";
import "../assets/css/headerStyle.css";
const UpdateInt = (props) =>  {

    const [newName , setNewName] = useState("");
    const [newAdress, setOption] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newTag, setNewTags] = useState("");
    const [newCategorie, setCathegorie] = useState("");
    const [newduree, setduree] = useState("");
    const [imageUpload, setImageUpload] = useState(null);

    const [OldName , setOldName] = useState("");
    const [oldAdress, setoldAdress] = useState("");
    const [OldEmail, setOldEmail] = useState("");
    const [oldTag, setoldTags] = useState([]);
    const [oldCategorie, setoldCathegorie] = useState("");
    const [oldduree, setoldduree] = useState("");
    const [oldimage, setoldImage] = useState("");


    const [user, loading, error] = useAuthState(auth);
    const [id, setId] = useState("");
    const navigate = useNavigate();


    const fetchInt = async (id , cat) => {
      try {
        const q = query(collection(db, `Organisme/${user.uid}/${cat}`), where("uidd", "==", id));
        const doc = await getDocs(q);
        const data = doc.docs[0].data();

         setOldName(data.nom);
       setoldAdress(data.adresse);
         setOldEmail(data.email);
        setoldTags(data.tags);
       setoldCathegorie(data.categorie);
       setoldImage(data.logo);
        setoldduree(data.tags);

        console.log(data.uidd)

      } catch (error) {
        console.error(error);
      }
    };




const [dureeInt, setDurerint]=useState(0);


useEffect(() => {

    
   
  if (loading) {

      
      
    return;
  }
  if (user) {
    
  
  
    fetchInt(props.id , props.cat);
}
    
  
  
 


}, [user, loading]);


  function load (){

    setTimeout(function(){
        window.location.reload(true);
    console.log("ok loade");
    },5000);

}
 const [validation, setValider]= useState("");
 const [validationN, setValiderN]= useState("");
 const [erreur, seterror] = useState("");
 const [tagsTab , settagss]=useState([]);

 function devider (tag){
  var tab = tag.split(" ");


  for(var i = 0; i < tab.length; i++){
   console.log(" " + tab[i] + " ");
   tab[i]="#"+tab[i];

  }
  settagss(tab)
 }

 const update = async (e) => {
  var tab = newTag.split(" ");


    for(var i = 0; i < tab.length; i++){
     console.log(" " + tab[i] + " ");
     tab[i]="#"+tab[i];

    }

    if(imageUpload == null && newName == "" && newAdress== "" && newEmail == "" && newduree=="" && newTag=="") return(seterror("Vous n'avez rien modifier"))


    const imageRef = ref(storage, `images/logoInter/${imageUpload.name}`);
    const path = `https://firebasestorage.googleapis.com/v0/b/startinblock-fd5c0.appspot.com/o/images%2FlogoInter%2F${imageUpload.name}?alt=media`;
   uploadBytes(imageRef , imageUpload).then(() => {
     console.log(path);
 });
     e.preventDefault()
     try {
      console.log(props.id)

      const userDoc = doc(db, `Organisme/${user.uid}/${props.cat}`, props.id);
       updateDoc(userDoc, { adresse: newAdress, email: newEmail, tags: tab, nom: newName, logo: path , etat: true ,duree: newduree }).then( setValider("L'intervenant a été modifier"),navigate("/ListeInter"));
      
      // const userDoc = collection(db, "Organisme", user.uid, props.cat, props.id);
      // const cityRef = collection(db, "Organisme", user.uid , props.cat,props.id).set ({ adresse: newAdress, email: newEmail, tags: tab, nom: newName, logo: path , etat: true ,duree: newduree }).t;
      // await collection(db, "Organisme", user.uid , props.cat , props.id).update({ adresse: newAdress, email: newEmail, tags: tab, nom: newName, logo: path , etat: true ,duree: newduree });
      
      // const path=`Organisme/${user.uid}/${props.cat}`;
      // const docRef = doc(db, "Organisme", user.uid, props.cat, props.id);
      //  const res = await docRef.setDoc( {
      //     adresse: newAdress, email: newEmail, tags: tab, nom: newName, logo: path , etat: true ,duree: newduree
      //   }).then(seterror(""),setValider("Vos informations ont été modifier") , navigate("/ListeInter"));
      
      

      } catch (err) {
      setValiderN("veuillez remplir les champs correctement");
      console.log(user.uid)
      console.log(err)

     }
    





 }


    return (
<section>
        <form id="AddOrganization" className='AddOrganization'>
                <div className="box-title-login">
                    <h3 className="titre_2">Modifier l'intervenant</h3>
                    <p style={{color: "red", textAlign:"center"}}>{validationN}</p>
                    <p style={{color: "red", textAlign:"center"}}>{erreur}</p>
                    <p style={{color: "green", textAlign:"center"}}>{validation}</p>
                </div>
                <label >Logo de l'intervenant (.PNG 250*250)</label>
                <label className="uploadFile">
                <span className="filename"></span>
                <input onChange={(event) => {setImageUpload(event.target.files[0])}}  type="file" className="form-controll Addorg" name="file" accept="image/png, image/jpeg" defaultValue={oldimage}/>
                 </label>
                <label >Nom de l'intervenant</label>
                <input onChange={(event) => {setNewName(event.target.value);}} id="name" className="Addorg" name="org_name" tabIndex="1" aria-required="true"  type="text" defaultValue={OldName}/>
                <label >Adress de l'intervenant</label>
                <input onChange={(event) => {setOption(event.target.value);}} id="name" className="Addorg" name="org_name" tabIndex="1" aria-required="true"  type="text" defaultValue={oldAdress}/>
                <label >Email de l'intervenant</label>
                <input onChange={(event) => {setNewEmail(event.target.value);}} id="email" className="Addorg" name="org_name" tabIndex="1" aria-required="true"  type="text" defaultValue={OldEmail}/>
                <label >Les tags (Tapez un espace entre les tags)</label>

                <input onChange={(event) => {setNewTags(event.target.value);}} id="tag" className="Addorg" name="org_name" tabIndex="1" aria-required="true"  type="text" defaultValue={oldTag}/>
                <label >Durée en mois</label>

                <input onChange={(event) => {setduree(event.target.value);}} id="tag" className="Addorg" name="org_name" tabIndex="1" aria-required="true"  type="number" defaultValue={0}/>

                {/* <label style={{marginRight:"10px"}}>Catégorie</label>
                <select multiple name='selection' className="Addorg" onChange={(event) => {setCathegorie(event.target.value);}}>
                    <option value="etudedemarche">Étude de marché</option>
                    <option value="Prototypage">Prototypage</option>
                    <option value="Financement">Financement</option>
                    <option value="Production">Production</option>
                    <option value="Commercialisation">Commercialisation</option>
                </select> */}
                <div style={{textAlign:'center'}}>
                <button onClick={update} className="add">Modifier</button>
                </div>
        </form>
        </section>
        );
    }
    export default UpdateInt;