import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth , logInWithEmailAndPassword, signInWithGoogle, registerWithEmailAndPassword, db , storage, registerWithEmailAndPasswordOrg, logout} from "../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {signOut} from "firebase/auth"
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

import { ref , uploadBytes } from "firebase/storage";
// import '../assets/css/style.css';
// import '../assets/css/shortcodes.css';
// import '../asset/css/bootstrap.css';


const CreeOrg= ()=> {
   
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [nom, setNom] = useState("");
    const [adress, setAdress] = useState("");
    const [ville , setVille] = useState("");
    const [erreur, seterror] = useState("");
    const [erreur1, seterror1] = useState("");
    const [erreur2, seterror2] = useState("");
    const [erreur3, seterror3] = useState("");
    const [erreur4, seterror4] = useState("");
    const [erreur5, seterror5] = useState("");
    const [erreur6, seterror6] = useState("");
    const [erreur7, seterror7] = useState("");
    const [erreur8, seterror8] = useState("");
    const [erreur9, seterror9] = useState("");
    const [imageUpload, setImageUpload] = useState(null);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
  
  
   
    




  useEffect(() => {

    
    if (loading) {

      
      
      return;
    }
    if (user) {
      
    
    navigate("/");
  }
  }, [user, loading]);

  const Add = async (e) => {
    
    if(imageUpload == null && nom =="" &&  email =="" && password =="" &&   adress =="" && ville =="") 
    return (seterror("Veuillez remplire le formulaire correctement!")) 
    if(imageUpload == null) 
    return (seterror1("Veillez inserez une image")) 

    else if(nom =="")return (seterror2("Veillez saisire votre nom")) 
    else if(email =="")return (seterror3("Veillez saisire votre nom")) 
    else if(password =="")return (seterror4("Veillez saisire votre nom")) 
    
    else if(adress =="")return (seterror7("Veillez saisire votre adress")) 
    else if(ville =="")return (seterror8("Veillez choisir votre ville")) ;
   
    const imageRef = ref(storage, `images/logo/${imageUpload.name}`);   
    const logo = `https://firebasestorage.googleapis.com/v0/b/startinblock-fd5c0.appspot.com/o/images%2Flogo%2F${imageUpload.name}?alt=media`;
   uploadBytes(imageRef , imageUpload).then(() => {
     console.log(logo);
     e.preventDefault()
     try {
      
       registerWithEmailAndPasswordOrg(nom, email, password, adress, logo, ville);
       
       
     } catch (error) {
       alert(error)
     }     
 });  
      
       
 }
  
      return (
  <section style={{textAlign: 'left'}}>
                  
                                      
                                         {/* ref={email}  ref={password} */}
                                      
                                          <div className="box-title-login">
                                          
                                          <h5>Créer un compte</h5>
                                          <p style={{color: "red", textAlign:"center"}}>{erreur}</p>
                                          </div>
                                          <label>Logo de l'Organisme(.PNG 250*250)</label>
                                            <label className="uploadFile">
                                            <span className="filename"></span>
                                            <p style={{color: "red"}}>{erreur1}</p>
                                            
                                            <input onChange={(event) => {setImageUpload(event.target.files[0])}}  type="file"  className="form-control Addorg" name="file" accept="image/png, image/jpeg" />
                                            </label>
                                            <br></br>
                                          <label>Address mail</label>
                                          <input value={email} onChange={(e) => setEmail(e.target.value)} id="email" name="email" tabIndex="1" aria-required="true" required type="email" placeholder="Email" />
                                          <p style={{color: "red"}}>{erreur3}</p>
                                          <label>Mot de passe</label>
                                          <input value={password} onChange={(e) => setPassword(e.target.value)} id="password" name="password" tabIndex="1" aria-required="true" required type="password" placeholder="Mot de passe" />
                                          <p style={{color: "red"}}>{erreur4}</p>
                                          <label>Nom de l'Organisme</label>
                                          <input value={nom} onChange={(e) => setNom(e.target.value)} id="nom" name="nom" tabIndex="1" aria-required="true" required type="text" placeholder="Nom de l'Organisme" />
                                          <p style={{color: "red"}}>{erreur2}</p>
                                          {/* <label>Verifier votrer mot de passe</label>
                                          <input value={password} onChange={(e) => setPassword(e.target.value)} id="password" name="password" tabIndex="1" aria-required="true" required type="password" placeholder="Confirmer votre mot de passe" /> */}
                                          
  
                                          
                                          <label>Adresse de l'organisme</label>
                                          <input  onChange={(e) => {setAdress(e.target.value)}} id="Adresse" name="Adresse" tabIndex="2"  aria-required="true" type="text" placeholder="Adresse de l'organisme" required />
                                          <p style={{color: "red"}}>{erreur7}</p>
                                          <label style={{marginRight:'10px'}}>Province</label>
                                          <select name="ville" id="pet-select" value={ville} onChange={(e) => setVille(e.target.value)}>
                                              <option valut="">--Choisire une Province--</option>
                                              <option value="Canada">Canada</option>
                                              <option value="Colombie-Britannique">Colombie-Britannique</option>
                                              <option value="Alberta">Alberta</option>
                                              <option value="Saskatchewan">	Saskatchewan</option>
                                              <option value="Manitoba">Manitoba</option>
                                              <option value="Ontario">Ontario</option>
                                              <option value="Québec">Québec</option>
                                              <option value="Terre-Neuve-et-Labrador">Terre-Neuve-et-Labrador</option>
                                              <option value="Nouveau-Brunswick">Nouveau-Brunswick</option>
                                              <option value="Nouvelle-Écosse">Nouvelle-Écosse</option>
                                              <option value="Île-du-Prince-Édouard">Île-du-Prince-Édouard</option>
                                              <option value="Nunavut">Nunavut</option>
                                              <option value="Territoires du Nord-Ouest">Territoires du Nord-Ouest</option>
                                              <option value="Yukon">Yukon</option>
                                              <option value="Tunis">Tunis</option>
                                          </select>
                                          <p style={{color: "red"}}>{erreur8}</p>
                                          {/* <label className='remember'>Remember me
                                          <input className='checkbox' type="checkbox"/>
                                          </label> */}
                                          {/* <Link to="#" className="remember">Forgot Password?</Link>
                                           */}
                                           <div style={{textAlign:'center'}}>
                                          <button onClick={Add} className="submit">Ajouter</button>
                                          </div>
                                          {/* <Link to="/" className="remember" >Se connecter</Link> */}
                                          {/* <button className="login__btn login__google" onClick={signInWithGoogle}>Login with Google</button> */}
                                          
                                      
                                 
  
                             
  
                      
              </section>
      );
   }
  export default CreeOrg;