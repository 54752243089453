import '../assets/css/style.scss';
import '../assets/css/headerStyle.css';
import { Link } from 'react-router-dom';


import StartupListv from "../pages/StartupListvalide";
import StartupListn from "../pages/SatrtupListNonvalide";
import { auth, db, logout } from "../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { query, collection, getDocs, where } from "firebase/firestore";
import ListeInter from '../pages/ListeInterv';
import logo from "../assets/img/logo.jpeg";
import AddContributor from "../pages/AddContributor";




function HeaderM() {

  

  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "Organisme"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setName(data.uid);
      console.log(data.uid)
      
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    console.log(user.uid)
     fetchUserName();
  }, [user, loading]);

  

    return (
      //   <div className="navbar">
      //     <div className='contentmenu'>
      //   <h2 className="titre_2">Startinblock</h2>
      //   <div className='menu'>
      // <Link to="/AddContributor" className="menuop">Ajouter intervenant</Link>
      // {/* <Link to="/ListeInter" className="menuop">Liste des intervenants</Link>  */}
      // <Link to="/AddContributor" className="menuop">Liste des startups</Link>  
          
      
        
      //   </div>
      //   <button onClick={logout} className="logout">Logout</button>
      //    </div>
      // </div>

<div>

  <aside className="sidebar">
  <img src={logo} alt="Logo" className='logoStart'/>
    <nav className="nav">
    
      <ul className='menu'>
        <li ><a href="/StartupList" >Liste des startups</a></li>
        <li ><a href="/AddContributor" >Ajouter des intervenants</a></li>
        <li ><a href="/ListeInter" >Liste des intervenants</a></li>
        <li ><a href="/UpdateOrg" >Modifier mes informations</a></li>
        
        <li><a href="" onClick={logout}>Se Deconnecter</a></li>
      </ul>
    </nav>
  </aside>



</div>
    );
  }
  
  export default HeaderM;
  