
import React , { useState , Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataOrg from '../assets/fake-data/data-live-auction';
// import Header from '../components/HeaderM';
import ListOrg from '../components/ListOrg';
import AddCon from "../components/Addcon";
import ListeInter from '../components/ListeInter';
import Navbar from '../components/Nav/Navbar';


//  import '../assets/css/style.css';
// import '../assets/css/shortcodes.css';
// import '../asset/css/bootstrap.css';



const ListeInterv= ()=> {
    
   
    return (
        <div>
            
        <Navbar />
        
        {/* <Addorg /> */}
         <div >
        
         <div style={{padding:"10% 10%"}}>
        <ListeInter/> 
        </div>
        </div>
     
        </div>

        
        
    );
  }
  export default ListeInterv;